import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { Button, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconGauge,
  IconTableImport,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomMenu from '../../../../components/CustomMenu';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import CounterUserService from '../../../../services/CounterUserService';
import type { CounterTypeResponse } from '../../../../types/api/response/counterType';
import type { CounterUserResponse } from '../../../../types/api/response/counterUser';
import type { DivisionResponse } from '../../../../types/api/response/division';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import { hasPermission, isAccountant } from '../../../../utils/authorization';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import { BulkUpdatesCounters } from './BulkUpdatesCounters';
import EditUserCounterForm from './EditUserCounterForm';

export type UsersCountersData = {
  userId: string;
  userFullname: string;
  division?: DivisionResponse;
  countersUsers?: CounterUserResponse[];
};

type Props = {
  divisions: DivisionResponse[];
  data?: UsersCountersData[];
  counterTypeResponses?: CounterTypeResponse[];
  companyId: string;
};

const componentName = 'UsersCounters';

export default function UsersCounters({
  data,
  counterTypeResponses,
  divisions,
  companyId,
}: Props) {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const counterTypes = useMemo(
    () =>
      counterTypeResponses
        ?.map((item) => item.counterType)
        .filter((elt) => elt.active),
    [counterTypeResponses]
  );
  const [users, setUsers] = useState<UsersCountersData[]>([]);

  const { mutate: exportUsersCounters, isLoading } = useMutation({
    mutationFn: () => CounterUserService.exportUsersCounters(companyId),
    onSuccess: (data) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-users-counters-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-users-counters-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  const rightCustomActions = [
    <CustomMenu
      key={'bulk-update-counters'}
      buttonLabel={t('w.bulkUpdates')}
      leftSection={<IconEdit />}
      buttonVariant={'white'}
      menuLabel={t('w.holidayCounters')}
      menuItems={
        counterTypes
          ? counterTypes.map((counterType) => ({
              label: getTranslatedKey(t, counterType.key),
              icon: <IconGauge size={18} />,
              onClick: () => {
                modals.open({
                  modalId: 'bulk-update-counters-modal',
                  title: (
                    <Title size={'h3'} component="p">
                      {`${t('w.bulkUpdates')} : ${getTranslatedKey(
                        t,
                        counterType.key
                      )}`}
                    </Title>
                  ),
                  size: 'xl',
                  children: (
                    <BulkUpdatesCounters
                      counterType={counterType}
                      selectedUsers={users}
                    />
                  ),
                });
              },
            }))
          : []
      }
      buttonDisabled={!counterTypes?.length || !users?.length}
    />,
    <Button
      key={'export-button'}
      disabled={
        (!hasPermission(
          {
              permission: BuiltInPermissions.ValidateHolidayCounter,
              scope: ScopeEnum.ALL,
            },
          user
        ) &&
          !isAccountant(user, companyId)) ||
        isLoading
      }
      leftSection={<IconTableImport />}
      variant={'filled'}
      loading={isLoading}
      onClick={() => exportUsersCounters()}
    >
      {t('w.export')}
    </Button>,
  ];

  const columns: DataTableColumn<UsersCountersData>[] = useMemo(() => {
    const result: DataTableColumn<UsersCountersData>[] = [
      {
        accessor: 'userFullname',
        title: t('w.employee'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, userFullname }) => (
          <GoToUserButton userId={userId} userFullname={userFullname} />
        ),
      },
      {
        accessor: 'division.name',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
    ];

    counterTypes?.forEach((counterType) => {
      result.push({
        accessor: counterType.id,
        title: getTranslatedKey(t, counterType.key),
        sortable: true,
        ellipsis: true,
        render: ({ countersUsers, userId, division }) => (
          <EditUserCounterForm
            disabled={division?.name === NO_WORK_UNIT}
            key={counterType.id}
            userId={userId}
            counterType={counterType}
            value={
              countersUsers?.find(
                (item) => item.counterTypeId === counterType.id
              )?.value
            }
            date={
              countersUsers?.find(
                (item) => item.counterTypeId === counterType.id
              )?.date
            }
          />
        ),
      });
    });

    return result;
  }, [counterTypes, data]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'division.name', direction: 'asc' },
    search: {
      accessor: 'userFullname',
      label: t('w.employee'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'division.name',
          label: t('w.unit'),
          choices: divisions.map((division) => ({
            value: division.name,
            label:
              division.name === NO_WORK_UNIT
                ? t('NO_WORK_UNIT')
                : division.name,
          })),
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      onSelect={(_users) => setUsers(_users as UsersCountersData[])}
      idAccessor="userId"
      pinFirstColumn
      rows={data ? data : []}
      options={options}
      lang={lang}
      withTableBorder={false}
      columns={columns}
      fetching={!data || !counterTypeResponses}
      rightCustomActions={rightCustomActions}
      height={'calc(100vh - 200px)'}
    />
  );
}
