import {
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Space,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';

import ButtonCancel from '../../../../components/ButtonCancel';
import ButtonSave from '../../../../components/ButtonSave';
import InputSkeleton from '../../../../components/InputSkeleton';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchLeaveValidators from '../../../../hooks/useFetchLeaveValidators';
import DivisionService from '../../../../services/DivisionService';
import type { DivisionRequest } from '../../../../types/api/payload/division';
import type { CompanyResponse } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import AssignEmployeesToDivisionTable from './AssignEmployeesToDivisionTable';

type Props = {
  onClose: () => void;
  refetch: () => void;
  divisionId?: string;
  company: CompanyResponse;
};

export default function EditWorkUnitForm({
  onClose,
  refetch,
  divisionId,
  company,
}: Props) {
  const { t } = useI18n();
  const { validatorsOptions, loading } = useFetchLeaveValidators();

  const form = useForm<DivisionRequest>({
    initialValues: {
      name: '',
      managerN1: '',
      managerN2: '',
      assistant: '',
    },
  });

  useQuery({
    enabled: !!divisionId,
    queryKey: ['DivisionService.getDivisionById', divisionId],
    queryFn: () => DivisionService.getDivisionById(divisionId as string),
    onSuccess: (data) => {
      const result = {
        name: data.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : data.name,
        managerN1: data.n1 ? data.n1 : '',
        managerN2: data.n2 ? data.n2 : '',
        assistant: data.assistant ? data.assistant : '',
      };
      form.setInitialValues(result);
      form.setValues(result);
    },
    onError: (err) => {
      showNotification({
        id: 'get-division-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      });
      onClose();
    },
  });

  const { mutate: createDivision, isLoading: isCreateDivisionLoading } =
    useMutation({
      mutationFn: (variables: DivisionRequest) =>
        DivisionService.create(company.id, variables),
      onSuccess: (data) => {
        refetch();
        showNotification({
          id: `create-divisionId-${data?.id}`,
          title: t('w.success'),
          message: t('success.division.created', data?.name),
          color: 'green',
          icon: <IconCheck />,
        });
        modals.open({
          modalId: `assign-division-${data.id}`,
          title: (
            <Title component="p">
              {t(
                'division.message.assign',
                data.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : data.name
              )}
            </Title>
          ),
          fullScreen: true,
          children: (
            <AssignEmployeesToDivisionTable division={data} company={company} />
          ),
          onClose: () => {
            refetch();
            onClose();
          },
        });
      },
      onError: (error) => {
        showNotification({
          id: `create-division-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  const { mutate: updateDivision, isLoading: isUpdateDivisionLoading } =
    useMutation({
      mutationFn: (variables: DivisionRequest) =>
        DivisionService.update(divisionId!, variables),
      onSuccess: (data) => {
        refetch();
        onClose();
        showNotification({
          id: `update-divisionId-${data?.id}-successful`,
          title: t('w.success'),
          message: t('success.division.updated', data?.name),
          color: 'green',
          icon: <IconCheck />,
        });
      },
      onError: (error) => {
        showNotification({
          id: `udpate-division-error`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  function handleDivisionFormSubmit(values: DivisionRequest) {
    if (divisionId) {
      updateDivision({
        name: values.name,
        managerN1: values.managerN1,
        managerN2: values.managerN2,
        assistant: values.assistant,
      });
    } else {
      createDivision({
        name: values.name,
        managerN1: values.managerN1,
        managerN2: values.managerN2,
        assistant: values.assistant,
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleDivisionFormSubmit(values))}
    >
      <LoadingOverlay
        visible={isCreateDivisionLoading || isUpdateDivisionLoading}
      />
      <Space h={'lg'} />
      <Grid gutter={'xl'}>
        <Grid.Col>
          <TextInput
            label={<LabelText text={t('w.name')} />}
            required
            placeholder={t('w.required')}
            disabled={form.values.name === t('NO_WORK_UNIT')}
            data-autofocus
            {...form.getInputProps('name')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          {loading ? (
            <InputSkeleton />
          ) : (
            <Select
              label={<LabelText text={t('w.validatorN1')} />}
              data={validatorsOptions.filter(
                (item: { value: string }) =>
                  item.value !== form.values.managerN2 &&
                  item.value !== form.values.assistant
              )}
              clearable
              placeholder={t('w.required')}
              {...form.getInputProps('managerN1')}
            />
          )}
        </Grid.Col>
        {company?.useN2 && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            {loading ? (
              <InputSkeleton />
            ) : (
              <Select
                label={<LabelText text={t('w.validatorN2')} />}
                data={validatorsOptions.filter(
                  (item: { value: string }) =>
                    item.value !== form.values.managerN1 &&
                    item.value !== form.values.assistant
                )}
                clearable
                placeholder={t('w.required')}
                {...form.getInputProps('managerN2')}
              />
            )}
          </Grid.Col>
        )}
        {company?.useAssistant && (
          <Grid.Col span={{ base: 12, md: 6 }}>
            {loading ? (
              <InputSkeleton />
            ) : (
              <Select
                label={<LabelText text={t('w.assistant')} />}
                data={validatorsOptions.filter(
                  (item: { value: string }) =>
                    item.value !== form.values.managerN1 &&
                    item.value !== form.values.managerN2
                )}
                clearable
                placeholder={t('w.required')}
                {...form.getInputProps('assistant')}
              />
            )}
          </Grid.Col>
        )}
      </Grid>
      <Space h={'xl'} />
      <Space h={'xl'} />
      <Group justify={'flex-end'}>
        <ButtonCancel onClose={onClose} />
        <ButtonSave />
      </Group>
    </form>
  );
}
