import { ActionIcon } from '@mantine/core';
import { useMove, UseMovePosition } from '@mantine/hooks';
import { IconSquarePlus } from '@tabler/icons-react';
import dayjs, { type Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import DailyWorkService, {
  UserPresenceHelper,
} from '../../services/DailyWorkService';
import {
  DailyWorkDetails,
  DailyWorkDetailsStatus,
  LeaveDetails,
} from '../../types/api/response/dailyWork';
import { LeaveTypeResponse } from '../../types/api/response/leaveType';
import type { LeaveEvent } from './DayEventsCell';
import css from './MonthEventScheduler.module.sass';
import SchedulerEventCell, {
  SchedulerEventCellResizeEvent,
} from './SchedulerEventCell';

export declare type EventColumnInlineStyle = {
  left: string;
};

export declare type SchedulerDayEventsColumnProps = {
  firstDayOfScheduler: Dayjs;
  dailyWork: DailyWorkDetails;
  userId: string;
  refetchDailyWorkListOnPeriod: () => void;
  nbDaysDisplayed: number;
};

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export default function SchedulerDayEventsColumn({
  firstDayOfScheduler,
  dailyWork,
  userId,
  refetchDailyWorkListOnPeriod,
  nbDaysDisplayed,
}: SchedulerDayEventsColumnProps) {
  const CELL_HEIGHT = 48;
  const events: LeaveEvent[] = useMemo(
    () =>
      dailyWork.leaves.map((leave) => {
        const leaveType = cloneDeep(leave).type!;

        return {
          dailyWorkId: dailyWork.id,
          division: dailyWork.divisionConcerned
            ? dailyWork.divisionConcerned
            : dailyWork.user.division.id,
          bgColor:
            leave.status?.state === 'REJECTED'
              ? 'transparent'
              : leave.status?.state === 'WAITING'
              ? '#ADB5BD'
              : leaveType?.color
              ? leaveType.color
              : '#8ce99a',
          endDate: dayjs(leave.period.end).utc(),
          id: leave.id as string,
          isHourly: leave.nbMinutes !== undefined,
          isEditable: leave.status?.state === 'WAITING',
          startDate: dayjs(leave.period.start).utc(),
          leaveType,
          status: leave.status?.state,
        };
      }),
    [dailyWork]
  );

  const { ref: dayColumnRef } = useMove(handleEventMove);
  const [eventCellResizingWithTop, setEventCellResizingWithTop] =
    useState<SchedulerEventCellResizeEvent | null>(null);
  const [eventCellResizingWithBottom, setEventCellResizingWithBottom] =
    useState<SchedulerEventCellResizeEvent | null>(null);
  const [stateEvents, setStateEvents] = useState<LeaveEvent[]>(events);
  const [resizeStopped, setResizeStopped] = useState<boolean>(false);
  const hours: number[] = new Array(24).fill(0);

  useEffect(() => {
    if (!resizeStopped) return;
    setResizeStopped(false);

    //check if every events are equivalent, consequence : no update call
    const eventsAreEquals =
      events.length === stateEvents.length &&
      events.every((event, index) => {
        return (
          event.startDate.isSame(stateEvents[index].startDate) &&
          event.endDate.isSame(stateEvents[index].endDate)
        );
      });
    if (eventsAreEquals) return;

    const leaves: LeaveDetails[] = stateEvents
      .sort((a, b) => (a.startDate.isBefore(b.startDate) ? -1 : 1))
      .map((event) => ({
        period: {
          start: event.startDate.utc(false).unix() * 1000,
          end: event.endDate.utc(false).unix() * 1000,
        },
      }));

    const dailyWorkUpdate: DailyWorkDetails = {
      ...dailyWork,
      status: DailyWorkDetailsStatus.WAITING,
      leaves,
    };

    DailyWorkService.updateUserDailyWork(
      userId,
      UserPresenceHelper.toUserPresence(dailyWorkUpdate)
    ).then(refetchDailyWorkListOnPeriod);
  }, [stateEvents, resizeStopped]);

  const handleEventResizingWithTop = (
    cellResizeEvent: SchedulerEventCellResizeEvent | null
  ) => {
    setEventCellResizingWithTop(cellResizeEvent);
  };

  const handleEventResizingWithBottom = (
    cellResizeEvent: SchedulerEventCellResizeEvent | null
  ) => {
    setEventCellResizingWithBottom(cellResizeEvent);
  };

  const handleEventStopResize = () => {
    setEventCellResizingWithBottom(null);
    setEventCellResizingWithTop(null);
    setResizeStopped(true);
  };

  const handleEventDeletion = (eventToDelete: LeaveEvent) => {
    const updatedEvents = stateEvents.filter(
      (event) => event.id !== eventToDelete.id
    );
    setStateEvents(updatedEvents);
    setResizeStopped(true);
  };

  const handleEventCreation = (hour: number) => {
    const leaveType = {
      name: stateEvents.length > 0 ? stateEvents[0].leaveType.name : 'Présence',
    } as LeaveTypeResponse;

    let startDate = dayjs(dailyWork.date).add(hour, 'hour').utc(false);
    startDate =
      stateEvents.find(
        (event) =>
          event.startDate.isSameOrBefore(startDate) &&
          event.endDate.isAfter(startDate)
      )?.endDate ?? startDate;
    let endDate = dayjs(dailyWork.date)
      .add(hour + 1, 'hour')
      .utc(false);
    endDate =
      stateEvents.find(
        (event) =>
          event.startDate.isBefore(endDate) && event.endDate.isAfter(endDate)
      )?.startDate ?? endDate;

    const leaveEvent: LeaveEvent = {
      id: `in creation-${new Date().getTime()}`,
      startDate: startDate,
      endDate: endDate,
      leaveType,
      bgColor: stateEvents.length > 0 ? stateEvents[0].bgColor : '#eaeaea',
      isHourly: true,
      isEditable: true,
      division: dailyWork.divisionConcerned,
    };

    const updatedEvents = [...stateEvents, leaveEvent];
    setStateEvents(updatedEvents);
    setResizeStopped(true);
  };

  function hasConflicts(
    eventStartDate: Dayjs,
    eventEndDate: Dayjs,
    eventId: string
  ) {
    const eventsInConflict = stateEvents
      .filter((event) => event.id !== eventId)
      .filter(
        (event) =>
          (eventStartDate.isSameOrAfter(event.startDate) &&
            eventStartDate.isBefore(event.endDate)) ||
          (eventEndDate.isAfter(event.startDate) &&
            eventEndDate.isSameOrBefore(event.endDate)) ||
          (eventStartDate.isSameOrBefore(event.startDate) &&
            eventEndDate.isSameOrAfter(event.endDate))
      );

    return eventsInConflict.length > 0;
  }

  function handleEventMove(position: UseMovePosition) {
    const eventCellResizing =
      eventCellResizingWithTop != null
        ? eventCellResizingWithTop
        : eventCellResizingWithBottom != null
        ? eventCellResizingWithBottom
        : null;
    if (eventCellResizing == null) return;

    let deltaYInPx =
      eventCellResizing.cellRef.current!.parentElement!.offsetHeight *
        position.y -
      eventCellResizing.cellRef.current!.offsetTop;
    if (eventCellResizingWithBottom != null)
      deltaYInPx -= eventCellResizing.cellRef.current!.offsetHeight;

    if (deltaYInPx > -5 && deltaYInPx < 0) return;

    const nbQuarterDelta = Math.floor(deltaYInPx / (CELL_HEIGHT / 4));
    const quarterDeltaY = nbQuarterDelta * (CELL_HEIGHT / 4);

    if (Math.abs(quarterDeltaY) === 0) return;

    let nextOffsetHeight = eventCellResizing.cellRef.current!.offsetHeight;
    // let nextOffsetTop = eventCellResizing.cellRef.current!.offsetTop;
    if (eventCellResizingWithTop != null) {
      // nextOffsetTop += quarterDeltaY;
      nextOffsetHeight -= quarterDeltaY;
    }
    if (eventCellResizingWithBottom != null) {
      nextOffsetHeight += quarterDeltaY;
    }

    // pas d'event < à 15 min
    if (nextOffsetHeight < CELL_HEIGHT / 4) return;

    const updatedEventStartDate =
      eventCellResizingWithTop != null
        ? eventCellResizing.event.startDate.add(nbQuarterDelta * 15, 'minute')
        : eventCellResizing.event.startDate;
    const updatedEventEndDate =
      eventCellResizingWithBottom != null
        ? eventCellResizing.event.endDate.add(nbQuarterDelta * 15, 'minute')
        : eventCellResizing.event.endDate;

    if (
      hasConflicts(
        updatedEventStartDate,
        updatedEventEndDate,
        eventCellResizing.event.id
      )
    ) {
      return;
    }

    const updatedEvents: LeaveEvent[] = stateEvents.map((event) => {
      if (event.id === eventCellResizing.event.id) {
        const updatedEvent = {
          ...event,
          startDate: updatedEventStartDate,
          endDate: updatedEventEndDate,
        };
        eventCellResizing.event = updatedEvent;
        return updatedEvent;
      }

      return event;
    });

    setStateEvents(updatedEvents);
  }

  const columnStyle: EventColumnInlineStyle = {
    left: `calc(${
      dayjs(dailyWork.date).get('date') - firstDayOfScheduler.get('date')
    } * var(--scheduler-cell-width))`,
  };

  function onAddPresenceOnRestDay(day: Dayjs, divisionConcerned: string) {
    const leaveEvent: LeaveEvent = {
      id: `in creation-${new Date().getTime()}`,
      startDate: day.startOf('day'),
      endDate: day.endOf('day'),
      leaveType: { name: 'Présence' } as LeaveTypeResponse,
      bgColor: '#eaeaea',
      isHourly: true,
      isEditable: true,
      division: divisionConcerned,
    };

    const updatedEvents = [...stateEvents, leaveEvent];
    setStateEvents(updatedEvents);
    setResizeStopped(true);
  }

  return (
    <div
      ref={dayColumnRef}
      className={css['scheduler-events-day-column']}
      style={columnStyle}
    >
      {hours.map((_hour: number, index) => {
        return (
          <div
            className={css['scheduler-content-row-cell']}
            key={`columnHour_${index}`}
            id={`scheduler-row-cell_${
              dailyWork.divisionConcerned
                ? dailyWork.divisionConcerned
                : dailyWork.user.division.id
            }_${userId}_${dayjs(dailyWork.date).format('DDMMYYYY')}_${index}`}
          >
            {dailyWork.status === 'WAITING' ? (
              <div
                className={css['scheduler-content-row-cell-add-event']}
                onClick={() => {
                  handleEventCreation(index);
                }}
              >
                <ActionIcon variant={'transparent'} color="gray" size={'xl'}>
                  <IconSquarePlus />
                </ActionIcon>
              </div>
            ) : (
              ''
            )}
          </div>
        );
      })}
      {stateEvents.map((event, index) => {
        return (
          <SchedulerEventCell
            key={`${event.id}-${index}`}
            event={event}
            firstDayOfScheduler={firstDayOfScheduler}
            day={dayjs(dailyWork.date).utc()}
            onResizeWithTop={handleEventResizingWithTop}
            onResizeWithBottom={handleEventResizingWithBottom}
            onStopResize={handleEventStopResize}
            onEventDeleted={handleEventDeletion}
            nbDaysDisplayed={nbDaysDisplayed}
            divisionConcerned={dailyWork.divisionConcerned}
            onAddPresenceOnRestDay={onAddPresenceOnRestDay}
          />
        );
      })}
    </div>
  );
}
