import { List } from '@mantine/core';
import { useMemo } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';

export default function ImportPayslipsInfoList() {
  const { t } = useI18n();

  const listItems = useMemo(
    () => [
      t('w.downloadPdfFileFirstInfoMessage'),
      t('w.downloadPdfFileSecondInfoMessage'),
    ],
    []
  );

  return (
    <List size="sm" spacing="xs">
      {listItems.map((item, index) => (
        <List.Item key={`${item}-${index}`}>{item}</List.Item>
      ))}
    </List>
  );
}
