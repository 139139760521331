import { Space, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import CustomModal from '../../../../components/CustomModal';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import CompanyService from '../../../../services/CompanyService';
import type { CompanyAccountantInfoViewClient } from '../../../../types/api/response/accountant';
import AccountantDivisionsCustomerCompanyTable from './AccountantDivisionsCustomerCompanyTable';
import AssignGeneralManagersForm from './AssignGeneralManagersForm';

type Props = {
  opened: boolean;
  onClose: () => void;
  accountantsToConfigure: CompanyAccountantInfoViewClient | null;
  refetchAccountantCustomersData: () => void;
};

export default function AccountantConfigModal({
  opened,
  onClose,
  accountantsToConfigure,
  refetchAccountantCustomersData,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const { data: accountantEmployeesData } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, ['fullname', 'mail']),
  });

  const accountantEmployees = useMemo(() => {
    if (!accountantEmployeesData) {
      return [];
    }
    return accountantEmployeesData.map((employee) => {
      return {
        value: employee.id,
        label: employee.fullname as string,
      };
    });
  }, [accountantEmployeesData]);

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      fullScreen
      title={accountantsToConfigure?.customerCompany?.name}
    >
      <AssignGeneralManagersForm
        accountantsToConfigure={accountantsToConfigure}
        accountantEmployees={accountantEmployees}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
      <Text fz={'xl'} fw={'bold'} c={'dimmed'} mt="md">
        {accountantsToConfigure?.customerCompany?.divisionLabel
          ? accountantsToConfigure.customerCompany.divisionLabel
          : t('w.unit')}
      </Text>
      <Space h="xl" />
      <AccountantDivisionsCustomerCompanyTable
        accountantsToConfigure={accountantsToConfigure}
        accountantEmployees={accountantEmployees}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
    </CustomModal>
  );
}
