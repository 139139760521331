import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Button, Group, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBrandPocket,
  IconCheck,
  IconCirclePlus,
  IconEdit,
  IconEye,
  IconTableDown,
  IconTableImport,
  IconTrash,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import CustomMenu from '../../../components/CustomMenu';
import CustomTooltip from '../../../components/CustomTooltip';
import ImportModal from '../../../components/ImportModal';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import DivisionService from '../../../services/DivisionService';
import ProfileService from '../../../services/ProfileService';
import type { CompanyResponse } from '../../../types/api/response/company';
import { DivisionResponse } from '../../../types/api/response/division';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import WorkUnitDetails from '../../work-units/components/WorkUnitDetails';
import AssignEmployeesToDivisionTable from './components/AssignEmployeesToDivisionTable';
import EditWorkUnitForm from './components/EditWorkUnitForm';

const componentName = 'WorkUnits';
export default function WorkUnits() {
  const { user } = useAuth();
  const { divisionLabel } = user.company!;
  const { t, lang } = useI18n();
  const { id: companyId, company } = useCompany(user);
  const { divisions, isLoading, refetch } = useFetchDivisions({ companyId });

  const [
    isImportModalOpened,
    { open: openImportModal, close: closeImportModal },
  ] = useDisclosure(false);
  const { mutate: exportDivisions } = useMutation({
    mutationFn: (type: 'XLSX' | 'CSV') =>
      DivisionService.exportDivisions(companyId, type),
    onSuccess: (data, type: 'XLSX' | 'CSV') => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-divisions-success',
        title: t('w.success'),
        message: t('success.exportDivision', type),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-divisions-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });
  const actionButtons = useMemo(
    () => [
      <Button
        disabled={
          !hasPermission(
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            user
          )
        }
        key={'add-button'}
        onClick={!!company ? () => openCreate(company) : undefined}
        leftSection={<IconCirclePlus />}
      >{`${t('w.add')} ${
        divisionLabel ? divisionLabel : t('w.unit')
      }`}</Button>,
    ],
    [company]
  );
  const rightCustomActions = useMemo(
    () => [
      <Button
        disabled={
          !hasPermission(
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            user
          )
        }
        key={'import-button'}
        leftSection={<IconTableDown />}
        bg={'white'}
        variant={'subtle'}
        onClick={openImportModal}
      >
        {t('w.import')}
      </Button>,
      <CustomMenu
        key={'export-divisions'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableImport />}
        menuLabel={t(
          'w.entityVia',
          divisionLabel ? divisionLabel : t('w.unit')
        )}
        menuItems={[
          {
            label: 'XLSX',
            icon: <IconBrandPocket size={18} />,
            onClick: () => exportDivisions('XLSX'),
          },
          {
            label: 'CSV',
            icon: <IconBrandPocket size={18} />,
            onClick: () => exportDivisions('CSV'),
          },
        ]}
      />,
    ],
    [divisionLabel]
  );
  const [columns, setColumns] = useState<DataTableColumn<DivisionResponse>[]>(
    []
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'n1User.fullname',
          label: t('w.n1Validator'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });
  useEffect(() => {
    const _columns: DataTableColumn<DivisionResponse>[] = [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ name }) => (name === NO_WORK_UNIT ? t(NO_WORK_UNIT) : name),
      },
      {
        accessor: 'nbUsers',
        title: t('w.nbUsers'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'n1User.fullname',
        title: t('w.n1Validator'),
        sortable: true,
        ellipsis: true,
        render: ({ n1User }) => (n1User?.fullname ? n1User.fullname : ''),
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: 110,
        render: (division) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="green"
                onClick={
                  !!company ? () => openView(division, company) : undefined
                }
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                disabled={!hasAuthority(division)}
                onClick={
                  !!company ? () => openEdit(division, company) : undefined
                }
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={t(
                'division.message.assign',
                division.name === NO_WORK_UNIT
                  ? t('NO_WORK_UNIT')
                  : division.name
              )}
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                disabled={!hasAuthority(division)}
                onClick={
                  !!company ? () => openAssign(division, company) : undefined
                }
              >
                <IconUsersGroup size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={
                division.name === NO_WORK_UNIT
                  ? t('w.notDeletable')
                  : t('w.delete')
              }
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                disabled={
                  division.name === NO_WORK_UNIT || !hasAuthority(division)
                }
                onClick={() => openDelete(division)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ];
    const _options: Options = { ...options };
    if (company?.useN2) {
      _columns.splice(3, 0, {
        accessor: 'n2User.fullname',
        title: t('w.n2Validator'),
        sortable: true,
        ellipsis: true,
        render: ({ n2User }) => (n2User?.fullname ? n2User.fullname : ''),
      });

      _options?.filter?.splice(1, 1);

      _options?.filter?.splice(1, 0, {
        accessor: 'n2User.fullname',
        label: t('w.n2Validator'),
        choices: [],
        selectedChoices: [],
      });
    }
    if (company?.useAssistant) {
      _columns.splice(4, 0, {
        accessor: 'assistantUser.fullname',
        title: t('w.assistant'),
        sortable: true,
        ellipsis: true,
        render: ({ assistantUser }) =>
          assistantUser?.fullname ? assistantUser.fullname : '',
      });

      _options?.filter?.splice(2, 1);

      _options?.filter?.splice(2, 0, {
        accessor: 'assistantUser.fullname',
        label: t('w.assistant'),
        choices: [],
        selectedChoices: [],
      });
    }

    setColumns(_columns);
  }, [company, divisions]);

  useEffect(() => {
    if (divisions?.length) {
      let _options: Options = { ...options };
      const validatorsN1 = divisions
        .filter((item) => item.n1)
        .map((item) => item.n1User?.fullname!);
      _options = buildOptionsFilter(validatorsN1, 0);

      if (company?.useN2) {
        const validatorsN2 = divisions
          .filter((item) => item.n2)
          .map((item) => item.n2User?.fullname!);
        _options = buildOptionsFilter(validatorsN2, 1);
      }

      if (company?.useAssistant) {
        const validatorsAssistant = divisions
          .filter((item) => item.assistant)
          .map((item) => item.assistantUser?.fullname!);
        _options = buildOptionsFilter(validatorsAssistant, 2);
      }

      setOptions(_options);
    }
  }, [divisions, company]);
  function buildOptionsFilter(array: string[], filterIndex: number): Options {
    const uniqueArr = array.filter(
      (item: string, index: number) => array.indexOf(item) === index
    );

    const result = uniqueArr.map((item: string) => {
      return {
        value: item,
        label: item,
      };
    });

    const _options: Options = { ...options };
    if (_options?.filter?.[filterIndex]?.choices) {
      _options.filter[filterIndex].choices = result;
    }
    return _options;
  }
  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }
  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: openDelete } = useMutation({
    mutationFn: (division: DivisionResponse) =>
      ProfileService.listUsersByDivision(division.id, ['valid']),
    onSuccess: (data, division: DivisionResponse) => {
      const activeEmployees = data.filter((employee) => employee.valid);
      if (activeEmployees.length === 0) {
        modals.openConfirmModal({
          title: (
            <Title size={'h3'} component="p">
              {`${t('w.delete')} ${division.name}`}
            </Title>
          ),
          labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
          size: 'xl',
          onConfirm: () => deleteDivision(division),
        });
      } else {
        modals.openConfirmModal({
          title: (
            <Title size={'h3'} component="p">
              {`${t('w.delete')} ${division.name}`}
            </Title>
          ),
          children: (
            <Text c={'dimmed'}>
              {t('division.message.delete', division.name)}
            </Text>
          ),
          labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
          size: 'xl',
          onConfirm: () => deleteDivision(division),
        });
      }
    },
    onError: (error, division: DivisionResponse) =>
      showNotification({
        id: `usersListFromDivision-${division.id}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: deleteDivision } = useMutation({
    mutationFn: (division: DivisionResponse) =>
      DivisionService.deleteDivision(division.id),
    onSuccess: (data) => {
      showNotification({
        id: `delete-division-${data?.id}-success`,
        title: t('w.success'),
        message: `${data?.name} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: (error, division: DivisionResponse) =>
      showNotification({
        id: `delete-division-${division?.id}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function openCreate(_company: CompanyResponse) {
    modals.open({
      modalId: `create-division`,
      title: (
        <Title component="p">{`${t('w.add')} ${
          divisionLabel ? divisionLabel : t('w.unit')
        }`}</Title>
      ),
      size: 'xl',
      children: (
        <EditWorkUnitForm
          onClose={() => modals.close(`create-division`)}
          refetch={refetch}
          company={_company}
        />
      ),
    });
  }

  function openView(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `view-division-${division.id}`,
      title: (
        <Title component="p">
          {division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name}
        </Title>
      ),
      fullScreen: true,
      children: (
        <WorkUnitDetails
          divisions={divisions}
          company={_company}
          divisionConsultedId={division.id}
        />
      ),
    });
  }

  function openAssign(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `assign-division-${division.id}`,
      title: (
        <Title component="p">
          {t(
            'division.message.assign',
            division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name
          )}
        </Title>
      ),
      fullScreen: true,
      children: (
        <AssignEmployeesToDivisionTable
          division={division}
          company={_company}
        />
      ),
    });
  }

  function openEdit(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `edit-division-${division.id}`,
      title: (
        <Title component="p">
          {`${t('w.edit')} ${
            division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name
          }`}
        </Title>
      ),
      size: 'xl',
      children: (
        <EditWorkUnitForm
          onClose={() => modals.close(`edit-division-${division.id}`)}
          refetch={refetch}
          divisionId={division.id}
          company={_company}
        />
      ),
    });
  }

  function hasAuthority(division: DivisionResponse) {
    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.ALL,
        },
        user
      )
    ) {
      return true;
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.TEAM,
        },
        user
      )
    ) {
      return (
        division.n1 === user.id ||
        division.n2 === user.id ||
        division.assistant === user.id
      );
    }

    return false;
  }

  return (
    <ScreenWrapper
      title={company?.divisionLabel ? company.divisionLabel : t('w.units')}
      actionButtons={actionButtons}
      paper
      isSetTitle
    >
      <Table
        pinFirstColumn
        pinLastColumn
        rows={divisions}
        withTableBorder={false}
        options={options}
        columns={columns}
        lang={lang}
        fetching={isLoading}
        rightCustomActions={rightCustomActions}
      />
      <ImportModal
        opened={isImportModalOpened}
        onClose={closeImportModal}
        title={t('w.import')}
        companyId={companyId}
        refetch={refetch}
        importType={'divisions'}
      />
    </ScreenWrapper>
  );
}
