import { Box, ScrollArea } from '@mantine/core';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import type { UserDailyWork } from '../../types/front/dailyWork';
import { NO_WORK_UNIT } from '../../variables/GlobalVariables';
import css from './MonthEventScheduler.module.sass';
import SchedulerDailyWorkHeadDayCell from './SchedulerDailyWorkHeadDayCell';
import SchedulerDailyWorkHeadInfoCell from './SchedulerDailyWorkHeadInfoCell';
import SchedulerDayEventsColumn from './SchedulerDayEventsColumn';

export declare type MonthSchedulerProps = {
  periodDailyWorks: UserDailyWork;
  userId: string;
  refetchDailyWorkListOnPeriod: () => void;
  companyId: string;
  id?: string;
};

interface SchedulerInlineStyles {
  [Key: string]: number | string;
}

export default function MonthEventScheduler({
  periodDailyWorks,
  userId,
  refetchDailyWorkListOnPeriod,
  companyId,
  id,
}: MonthSchedulerProps) {
  const viewport = useRef<HTMLDivElement>(null);
  const { t } = useI18n();
  const { divisions } = useFetchDivisions({ companyId });
  const divisionsOptions = useMemo(
    () =>
      divisions?.map((item) => ({
        value: item.id,
        label: item.name === NO_WORK_UNIT ? t(NO_WORK_UNIT) : item.name,
      })),
    [divisions]
  );
  const hours = new Array(24).fill(0);
  const isHourly = !periodDailyWorks.dailyWorks[0].user.fixedPriceManager;
  const schedulerPanel = useRef<HTMLDivElement>(null);
  const scrollTo8oClock = () => {
    viewport.current!.scrollTo({
      top: viewport.current!.scrollHeight / 3,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isHourly) scrollTo8oClock();
  }, []);

  const schedulerInlineStyles: SchedulerInlineStyles = {
    '--scheduler-row-items-count': periodDailyWorks.dailyWorks.length,
    '--scheduler-cell-width': `${
      (schedulerPanel.current?.clientWidth! - 62) /
        periodDailyWorks.dailyWorks.length >
      117
        ? Math.round(
            (schedulerPanel.current?.clientWidth! - 62) /
              periodDailyWorks.dailyWorks.length
          ) + 'px'
        : '117px'
    } `,
  };

  const adaptHeaderAndLeftColumnToScroll = (position: {
    x: number;
    y: number;
  }) => {
    document.getElementById(
      `${userId}_scheduler-header-days`
    )!.style.transform = `translateX(${position.x * -1}px)`;
    document.getElementById(
      `${userId}_scheduler-header-workInfo`
    )!.style.transform = `translateX(${position.x * -1}px)`;

    document.getElementById(
      `${userId}_scheduler-content-row-left-column`
    )!.style.transform = `translateY(${position.y * -1}px)`;
  };

  return (
    <div
      id={id}
      className={css['scheduler']}
      style={schedulerInlineStyles}
      ref={schedulerPanel}
    >
      <div className={css['scheduler-header']}>
        <div className={css['scheduler-header-row']}>
          <div className={css['scheduler-header-row-empty-cell']}></div>
          <div
            className={css['scheduler-header-row-scrollable-cells']}
            id={`${userId}_scheduler-header-days`}
          >
            {periodDailyWorks.dailyWorks.map((dailyWork) => {
              return (
                <SchedulerDailyWorkHeadDayCell
                  dailyWork={dailyWork}
                  key={dailyWork.id}
                  refetchDailyWorksList={refetchDailyWorkListOnPeriod}
                  divisionsOptions={divisionsOptions}
                />
              );
            })}
          </div>
        </div>
        <div
          className={`${css['scheduler-header-row']} ${css['scheduler-header-row-workInfo']} `}
        >
          <div className={css['scheduler-header-row-empty-cell']}></div>
          <div
            className={`${css['scheduler-header-row-scrollable-cells']} ${css['scheduler-header-row-scrollable-cells-workInfo']}`}
            id={`${userId}_scheduler-header-workInfo`}
          >
            {periodDailyWorks.dailyWorks.map((dailyWork) => {
              return (
                <SchedulerDailyWorkHeadInfoCell
                  dailyWork={dailyWork}
                  key={dailyWork.id}
                  refetchDailyWorksList={refetchDailyWorkListOnPeriod}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={css['scheduler-content']}>
        <div className={css['scheduler-content-row']}>
          <div
            className={css['scheduler-content-row-left-column']}
            id={`${userId}_scheduler-content-row-left-column`}
          >
            {hours.map((_hour: number, index) => {
              return (
                <div
                  className={css['scheduler-content-row-cell']}
                  key={`leftColumn_${index}`}
                >
                  <div
                    key={index}
                    className={
                      css['scheduler-content-row-left-column-cell-hour']
                    }
                  >
                    {index < 23
                      ? `${dayjs.duration(index + 1, 'hour').format('HH:mm')}`
                      : ''}
                  </div>
                </div>
              );
            })}
          </div>

          <ScrollArea
            className={css['scheduler-content-row-scrollable']}
            onScrollPositionChange={adaptHeaderAndLeftColumnToScroll}
            viewportRef={viewport}
          >
            <Box className={css['scheduler-content-row-scrollable-cells']}>
              {periodDailyWorks.dailyWorks.map((dailyWork) => (
                <SchedulerDayEventsColumn
                  firstDayOfScheduler={dayjs(
                    periodDailyWorks.dailyWorks[0].date
                  ).utc()}
                  dailyWork={dailyWork}
                  key={`${userId}-${dayjs(dailyWork.date).format('DDMMYYYY')}-${
                    dailyWork.leaves.length
                  }-${dailyWork.workingTimeDay}-${dailyWork.overtime}-${
                    dailyWork.status
                  }`}
                  userId={userId}
                  refetchDailyWorkListOnPeriod={refetchDailyWorkListOnPeriod}
                  nbDaysDisplayed={periodDailyWorks.dailyWorks.length}
                />
              ))}
            </Box>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
