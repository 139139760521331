import qs from 'qs';

import type {
  BreakDays,
  PlanningObject,
  PublicHoliday,
  TimeRecorder,
} from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function getAllPublicHolidays(
  companyId: string,
  year1: number,
  year2: number
): Promise<PublicHoliday[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/planning/${companyId}/public-holidays`, {
      params: { year1, year2 },
    })
    .then((res) => res?.data);
}

function updatePublicHoliday(companyId: string, publicHolidayKey: string) {
  return request
    .patch(
      `${MS_PLANNING_ENDPOINT}/v2/planning/${companyId}/public-holidays`,
      qs.stringify({ publicHolidayKey })
    )
    .then((res) => res?.data);
}

function getPlanningFromStartAndEndDateByDivision(
  companyId: string,
  end: number,
  start: number,
  divisionId: string[]
): Promise<PlanningObject[] | undefined> {
  return request
    .get(
      `${MS_PLANNING_ENDPOINT}/v2/planning/company/${companyId}/${start}/${end}?${divisionId
        .map((item) => `divisionId=${encodeURIComponent(item)}`)
        .join('&')}`
    )
    .then((res) => res?.data);
}
function getExportPlanningFromStartAndEndDateByDivision(
  companyId: string,
  end: number,
  start: number,
  divisionId: string[]
) {
  return request
    .get(
      `${MS_PLANNING_ENDPOINT}/v2/planning/company/${companyId}/${start}/${end}/export?${divisionId
        .map((item) => `divisionId=${encodeURIComponent(item)}`)
        .join('&')}`,
      {
        responseType: 'blob',
      }
    )
    .then((res) => res);
}

function getAllBreakDayByDivision(
  companyId: string,
  divisionId: string,
  startTime: number,
  endTime: number
): Promise<BreakDays[]> {
  return request
    .get(
      `${MS_PLANNING_ENDPOINT}/v2/planning/company/${companyId}/employees/breakdays/${divisionId}/${startTime}/${endTime}`
    )
    .then((res) => res?.data);
}

function getUserTimeRecorderList(
  userId: string,
  start: string,
  end: string
): Promise<TimeRecorder[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/time-recorder/${userId}/list`, {
      params: { start: start, end: end },
    })
    .then((res) => res?.data);
}

const PlanningService = {
  getAllPublicHolidays,
  updatePublicHoliday,
  getPlanningFromStartAndEndDateByDivision,
  getExportPlanningFromStartAndEndDateByDivision,
  getAllBreakDayByDivision,
  getUserTimeRecorderList,
};

export default PlanningService;
