import { Flex, Group, Paper, Text } from '@mantine/core';
import { UseMutateFunction } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import { NotificationUser } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import s from './UserAppNotificationsItem.module.sass';

type Props = {
  notification: NotificationUser;
  markUserNotificationAsViewed: UseMutateFunction<
    any,
    unknown,
    { notificationId: string },
    unknown
  >;
};

export default function UserAppNotificationsItem({
  notification,
  markUserNotificationAsViewed,
}: Props) {
  const navigate = useNavigate();

  function consultClick() {
    if (!notification.viewed) {
      markUserNotificationAsViewed({
        notificationId: notification.id,
      });
    }
    if (notification.link) {
      try {
        const url = new URL(notification.link);
        navigate(`${url.pathname}${url.search}`);
      } catch (e) {
        navigate(notification.link);
      }
    }
  }

  return (
    <Paper p="xs" className={s.overviewWrapper} onClick={consultClick}>
      <Group
        styles={{
          root: {
            opacity: notification.viewed ? 0.5 : 1,
          },
        }}
        wrap="nowrap"
      >
        <CustomUserAvatar userId={notification.userId} size={48} />
        <Flex direction={'column'}>
          <Text fz={'sm'} fw={'bold'} c={'hifivework.6'}>
            {notification.title}
          </Text>
          <Text fz={'xs'} c={'dimmed'}>
            {notification.content}
          </Text>
          <Text fz={'xs'} c={'hifivework.5'}>
            {milliToFullDateString(`${notification.created}Z`)}
          </Text>
        </Flex>
      </Group>
    </Paper>
  );
}
