import { Button, Flex, SimpleGrid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import type {
  ModuleDetails,
  SynchroOpenpayeModuleResponse,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';

interface FormValues {
  username: string;
  password: string;
  isTest: boolean;
}

type Props = {
  module: ModuleDetails | null;
  companyId: string;
};

export default function SynchroOpenpayefeatureConnectionForm({
  module,
  companyId,
}: Props) {
  const { t } = useI18n();
  const [isTest, setIstest] = useState(false);
  const [moduleParams, setModuleParams] = useState<
    SynchroOpenpayeParams | undefined
  >(module?.configuration?.params as SynchroOpenpayeParams);
  const form = useForm<FormValues>({
    initialValues: {
      username: moduleParams?.username || '',
      password: moduleParams?.password || '',
      isTest: isTest,
    },
  });

  function handleUpdateOpenpayeParamsSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (module) {
      updateModuleParamsOpenpaye({
        apiUrl: module.description.url,
        payload: {
          active: module?.active ? module.active : false,
          username: values.username,
          password: values.password,
          isTest: values.isTest,
          dossier: moduleParams?.dossiers,
        },
      });
    }
  }

  const { mutate: updateModuleParamsOpenpaye } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (data: SynchroOpenpayeModuleResponse) => {
      setModuleParams(data.params);
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateOpenpayeParamsSubmit({
          username: values.username,
          password: values.password,
          isTest: isTest,
        })
      )}
    >
      <SimpleGrid cols={2}>
        <TextInput
          required
          label={<LabelText text={t('w.username')} />}
          {...form.getInputProps('username')}
        />
        <TextInput
          required
          label={<LabelText text={t('w.password')} />}
          {...form.getInputProps('password')}
        />
      </SimpleGrid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <Button
          type={'submit'}
          variant={'outline'}
          onClick={() => setIstest(true)}
        >
          {t('w.test')}
        </Button>
        <ButtonSave />
      </Flex>
    </form>
  );
}
