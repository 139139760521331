import type { IconName } from '../../../features/company/period-types/components/IconSelector';
import type { CommonEntity, DayOfWeek } from '../common';

export type ModuleDetails = {
  name: ModuleName;
  active: boolean;
  configuration: ModuleDetailsConfiguration;
  description: ModuleDetailsDescription;
};

export enum ModuleName {
  HfwAzureSync = 'hfwAzureSync',
  CompanyDaysWorked = 'companyDaysWorked',
  CompanyUseN2 = 'companyUseN2',
  CompanyUseAssistant = 'companyUseAssistant',
  ShuttleSheet = 'ShuttleSheet',
  BulletinCogilog = 'BulletinCogilog',
  BulletinIsapaye = 'BulletinIsapaye',
  GererMesAffaires = 'GererMesAffaires',
  TimeRecorder = 'TimeRecorder',
  DailyWork = 'DailyWork',
  ShowMessageBeforePaidLeaveRequest = 'ShowMessageBeforePaidLeaveRequest',
  ExpenseReport = 'ExpenseReport',
  SynchroOpenPaye = 'SynchroOpenPaye',
  ExportCegidExpert = 'ExportCegidExpert',
  ExportQuadra = 'ExportQuadra',
  ExportDIAPAIE = 'ExportDIAPAIE',
  ExportISAPAYE = 'ExportISAPAYE',
  ExportSilae = 'ExportSilae',
  VariablePayroll = 'VariablePayroll',
  CharteredAccountant = 'CharteredAccountant',
  HfwAccountant = 'hfwAccountant',
  HfwMyCompany = 'hfwMyCompany',
  Pappers = 'pappers',
  DpaeUrssaf = 'dpaeUrssaf',
  IncomeSimulatorUrssaf = 'incomeSimulatorUrssaf',
  Yoomonkeez = 'yoomonkeez',
  Vasa = 'vasa',
  MaitreDataLegiTempo = 'MaitreDataLegiTempo',
  MaitreDataEasyBDESE = 'MaitreDataEasyBDESE',
  MaitreDataLegiVision = 'MaitreDataLegiVision',
}

type ModuleDetailsConfiguration = {
  params?: ModuleConfigurationParams;
  active: boolean;
  clientId: string;
};

type ModuleDetailsDescription = {
  id: string;
  description: string;
  name: ModuleName;
  enabled: boolean;
  types: string[];
  url: string;
  views: string[];
  directUrl: boolean;
  imageUrl: string;
  activatable: boolean;
  paid: boolean;
  params: boolean;
};

export type ModuleConfigurationParams =
  | SynchroOpenpayeParams
  | TimeRecorderParams
  | GMAParams
  | ShowMessageBeforePaidLeaveRequestParams
  | ExportParams
  | HfwAzureSyncParams
  | CompanyDaysWorkedParams
  | ExpenseReportParams
  | VariablePayrollParams
  | DailyWorkParams;

export type SynchroOpenpayeParams = {
  username: string;
  password: string;
  dossiers?: DossierProps[];
  lastSynchroEmployee: number;
};

export type DossierProps = {
  dossierId: string;
  status?: boolean;
  employeeCount?: number;
};

export type TimeRecorderParams = {
  linkToDailyWork?: boolean;
  delayPeriod?: null | number;
};

export type GMAParams = {
  safeBeingInitialized?: boolean;
  existsOnGma?: boolean;
};

export type ShowMessageBeforePaidLeaveRequestParams = {
  message?: string;
};

export type ExportParams = {
  codesDossier?: string[];
  includeLeave?: boolean;
  includeVar?: boolean;
};

export type HfwAzureSyncParams = {
  login?: string;
  password?: string;
  tenant?: string;
  clientId?: string;
  clientSecret?: string;
  scope?: string;
};

export type CompanyDaysWorkedParams = {
  validDayOfWeek?: DayOfWeek[];
  colorIcons: ColorIcon[];
};

type ColorIcon = {
  color: string;
  icon: IconName;
  type: ColorIconType;
};

export enum ColorIconType {
  BREAK_DAY = 'BREAK_DAY',
  PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
  BLOCKING_PERIOD = 'BLOCKING_PERIOD',
}

export type ExpenseReportParams = {
  method: ExpenseReportParamsMethod;
};

enum ExpenseReportParamsMethod {
  PAYROLL = 'PAYROLL',
  DIRECTLY = 'DIRECTLY',
}

export type VariablePayroll = {
  id?: string;
  label: string;
  type: VariablePayrollType;
  editable: boolean;
  codeQuadra?: string;
  codeSilae?: string;
  codeDiapaie?: string;
  codeIsapaye?: string;
  codeOpenpaye?: string;
  codeCegidExpert?: string;
};

export enum VariablePayrollType {
  EUROS = 'EUROS',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  NUMBERS = 'NUMBERS',
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export type VariablePayrollParams = {
  variablesPayroll: VariablePayroll[];
};

export type DailyWorkParams = {
  activeForAll?: boolean;
  variableIdOvertime?: string;
  variableIdWorkingTime?: string;
  variableIdWorkingDays?: string;
  counterTypeIdOvertime?: string;
};

export type ModuleConfiguration = CommonEntity & {
  moduleDescriptionId: string;
  clientId: string;
  active: boolean;
};

export type SynchroOpenpayeModuleResponse = {
  active: boolean;
  params: SynchroOpenpayeParams;
  clientId: string;
  descriptionId: string;
  id: string;
  created?: string;
  updated?: string;
};

export type DailyworkModuleResponse = {
  active: boolean;
  params: DailyWorkParams;
  clientId: string;
  descriptionId: string;
  id: string;
  created?: string;
  updated?: string;
};
