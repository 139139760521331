import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useI18n } from '../contexts/I18nProvider';
import CompanyService from '../services/CompanyService';
import SecurityRoleService from '../services/SecurityRoleService';
import { BuiltInPermissions } from '../types/api/response/role';
import useCompany from './useCompany';

export default function useFetchLeaveValidators() {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);

  const {
    data: securityRoles,
    isLoading: securityRolesIsLoading,
    isError: securityRolesIsError,
  } = useQuery({
    queryKey: ['SecurityRoleService.list', companyId],
    queryFn: () => SecurityRoleService.list(companyId),
  });

  const {
    data: employees,
    isLoading: employeesIsLoading,
    isError: employeesIsError,
  } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'securityRoleId',
        'valid',
        'mail',
        'fullname',
      ]),
  });

  const validatorsOptions = useMemo(() => {
    if (!securityRoles || !employees) {
      return [];
    }
    const allowedRolesIds: string[] = [];
    securityRoles.forEach((role) => {
      if (
        role?.permissions
          ?.map((elt) => elt.permission?.key)
          .includes(BuiltInPermissions.ValidateLeavesAbsences)
      ) {
        allowedRolesIds.push(role.id);
      }
    });

    const employeesFilter = employees.filter(
      (employee) =>
        allowedRolesIds.includes(employee.securityRoleId!) &&
        Boolean(employee.valid)
    );
    employeesFilter.unshift({ id: '', mail: t('w.noValue') });

    return employeesFilter.map((employee) => {
      return {
        value: employee.id,
        label: employee.fullname
          ? `${employee.fullname} (${employee.mail})`
          : employee.mail!,
      };
    });
  }, [securityRoles, employees]);

  const loading: boolean = employeesIsLoading || securityRolesIsLoading;

  const error: boolean = securityRolesIsError || employeesIsError;

  useEffect(() => {
    if (error) {
      showNotification({
        id: 'error-response',
        title: t('w.error'),
        message: t('error.error_happened'),
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [securityRolesIsError, employeesIsError]);

  return { validatorsOptions, loading };
}
