import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Space,
  Text,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconFileSpreadsheet, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { ReactNode, useEffect, useState } from 'react';

import { useI18n } from '../contexts/I18nProvider';
import CompanyService from '../services/CompanyService';
import LeaveTypeService from '../services/LeaveTypeService';
import type {
  ImportDivisionResultResponse,
  ImportEmployeeResultResponse,
} from '../types/api/response/company';
import handleErrorMessage from '../utils/handleErrorMessage';
import CustomDropzone from './CustomDropzone/CustomDropzone';
import CustomModal from './CustomModal';
import ImportReportModal from './ImportReportModal';

type Props = {
  opened: boolean;
  onClose: () => void;
  title: string;
  companyId: string;
  refetch: () => void;
  importType: 'employees' | 'divisions' | 'period-types';
};

interface FormValues {
  files: FileWithPath[];
}

export default function ImportModal({
  opened,
  onClose,
  title,
  companyId,
  refetch,
  importType,
}: Props) {
  const { t } = useI18n();

  const [resultImportModal, setResultImportModal] = useState<
    ImportDivisionResultResponse | ImportEmployeeResultResponse | null
  >(null);

  const form = useForm<FormValues>({
    initialValues: {
      files: [],
    },
  });

  const { mutate: importEmployees, isLoading } = useMutation({
    mutationFn: (variables: { file: FileWithPath }) =>
      CompanyService.importData(
        importType as 'employees' | 'divisions',
        variables.file,
        companyId
      ),
    onSuccess: (data) => {
      setResultImportModal(data);
      refetch();
      showNotification({
        id: `import-${importType}-success`,
        title: t('w.success'),
        message:
          importType === 'employees'
            ? t('success.importEmployees')
            : t('w.success.importDivisions'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: `import-${importType}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const { mutate: importLeaveTypes } = useMutation({
    mutationFn: (variables: { file: FileWithPath }) =>
      LeaveTypeService.importLeaveTypes(companyId, variables.file),
    onSuccess: () => {
      refetch();
      onClose();
      showNotification({
        id: `import-leave-types-success`,
        title: t('w.success'),
        message: t('success.importLeaveTypes', 'XLSX'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: `import-leave-types-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  function resetFiles() {
    form.setFieldValue('files', []);
  }

  useEffect(() => {
    if (!opened) {
      resetFiles();
    }
  }, [opened]);

  function renderSubText(): ReactNode {
    if (importType !== 'employees') {
      return null;
    }
    return (
      <>
        <Text mb="md">
          Exporter les données via le bouton <b>Exporter</b> pour avoir le
          modèle d'import.
        </Text>
        <Text fz={'xs'}>
          Pour assigner le collaborateur à hors unité, utilisez le mot clé :{' '}
          <b>NO_WORK_UNIT</b>
        </Text>
        <Text fz={'xs'}>
          Pour assigner les rôles par défaut, utilisez les mots clés suivants :{' '}
          <b>employee</b> pour Employé, <b>manager</b> pour Manager,{' '}
          <b>direction</b> pour Direction, <b>administrator</b> pour
          Administrateur
        </Text>
      </>
    );
  }

  function handleImportFileSubmit(values: FormValues): void {
    if (importType !== 'period-types') {
      importEmployees({ file: values.files[0] });
    } else {
      importLeaveTypes({ file: values.files[0] });
    }
  }

  return (
    <CustomModal opened={opened} onClose={onClose} title={title} size={'xl'}>
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={form.onSubmit((values) => handleImportFileSubmit(values))}
      >
        <CustomDropzone
          files={form.values.files}
          onDrop={(files) => form.setFieldValue('files', files)}
          mimeType={'excel'}
          resetFiles={resetFiles}
        />
        <Space h={'md'} />
        {renderSubText()}
        {form.values.files.length > 0 && (
          <Flex mt={'md'} justify={'flex-end'}>
            <Button type={'submit'}>{t('w.import')}</Button>
          </Flex>
        )}
        <Space h={'md'} />
        {importType === 'divisions' && (
          <Group>
            <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
              {t('w.downloadTemplateFile')} :
            </Text>
            <Button
              variant={'subtle'}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={'/exemple/Divisions_modele_de_test.xlsx'}
              leftSection={<IconFileSpreadsheet />}
            >
              XLSX
            </Button>
            <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
              {t('w.or')}
            </Text>
            <Button
              variant={'subtle'}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={'/exemple/Divisions_modele_de_test.csv'}
              leftSection={<IconFileSpreadsheet />}
            >
              CSV
            </Button>
          </Group>
        )}
      </form>
      <ImportReportModal
        opened={resultImportModal !== null}
        onClose={() => {
          setResultImportModal(null);
          onClose();
        }}
        resultImportModal={resultImportModal}
        importType={importType}
      />
    </CustomModal>
  );
}
