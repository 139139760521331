import {
  Avatar,
  Button,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Space,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { DateInput, DatePicker } from '@mantine/dates';
import type { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import LeaveService from '../../../../services/LeaveService';
import LeaveTypeService from '../../../../services/LeaveTypeService';
import PlanningService from '../../../../services/PlanningService';
import { RepetitionType } from '../../../../types/api/payload/leave';
import {
  LeaveResponse,
  LeaveStatusState,
} from '../../../../types/api/response/leave';
import type { LeaveTypeResponse } from '../../../../types/api/response/leaveType';
import type { UserResponse } from '../../../../types/api/response/user';
import type { BreakDays } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import AvatarTooltip from './components/AvatarTooltip';

type Props = {
  onClose: () => void;
  refresh?: () => void;
  user: UserResponse;
  defaultDate?: Date;
};

interface FormValues {
  leaveTypeId: string;
  repetitionType: RepetitionType;
  repetitionEndDate: Date;
  dateRange: [Date, Date] | [undefined, undefined];
  start: string;
  end: string;
  comment: string;
  files: FileWithPath[];
}

export default function LeavesRequestFormModal({
  onClose,
  refresh,
  user,
  defaultDate,
}: Props) {
  const { t, lang } = useI18n();
  const theme = useMantineTheme();
  const eighteenMonthsMilli = useMemo(() => 46656000000, []);
  const currentMilli = useMemo(() => dayjs(new Date()).valueOf(), []);
  const queryClient = useQueryClient();

  const { data: leavesList } = useQuery({
    queryKey: ['LeaveService.list', user.id],
    queryFn: () => LeaveService.getLeavesHistory({ userId: user.id }),
  });

  const { data: publicHolidays } = useQuery({
    queryKey: ['PlanningService.getAllPublicHolidays', user.company?.id!],
    queryFn: () =>
      PlanningService.getAllPublicHolidays(
        user.company?.id!,
        dayjs().year(),
        dayjs().year() + 1
      ),
  });

  const { data: breakDays } = useQuery({
    queryKey: ['PlanningService.getAllBreakDayByDivision', user.company?.id!],
    queryFn: () =>
      PlanningService.getAllBreakDayByDivision(
        user.company?.id!,
        user.division?.id!,
        currentMilli - eighteenMonthsMilli,
        currentMilli + eighteenMonthsMilli
      ),
  });

  const userBreakDays: BreakDays | undefined = useMemo(() => {
    if (breakDays && breakDays?.length > 0) {
      return breakDays.find(
        (breakDay: BreakDays) => breakDay.userId === user.id
      );
    }
    return undefined;
  }, [breakDays]);

  const matches = useMediaQuery('(min-width: 56.25em)');

  const form = useForm<FormValues>({
    initialValues: {
      leaveTypeId: '',
      repetitionType: RepetitionType.NO_REPETITION,
      repetitionEndDate: new Date(),
      dateRange: defaultDate
        ? [defaultDate, defaultDate]
        : [undefined, undefined],
      start: 'wholeDay',
      end: 'wholeDay',
      comment: '',
      files: [],
    },
  });

  const calendarData = useMemo(() => {
    const userLeaves: { date: Date; color: string; label: string }[] = [];
    const _publicHolidays: { date: Date; color: string; label: string }[] = [];
    const _userBreakDays: { date: Date; color: string; label: string }[] = [];

    function buildPeriods(
      startDate: Date,
      endDate: Date,
      diff: number,
      color: string,
      label: string
    ) {
      if (dayjs(startDate).valueOf() !== dayjs(endDate).valueOf()) {
        for (let i = 0; i < diff; i++) {
          userLeaves.push({
            date: dayjs(startDate)
              .add(i + 1, 'day')
              .toDate(),
            color: color,
            label: label,
          });
        }
      }
    }

    leavesList?.forEach((leave: LeaveResponse) => {
      const startDate = new Date(leave.period.start);
      const endDate = new Date(leave.period.end);
      const startDateMilli = dayjs(startDate).valueOf();
      const endDateMilli = dayjs(endDate).valueOf();
      const diff = (endDateMilli - startDateMilli) / 86400000;

      if (leave.leaveType?.companyMandatoryLeaveType) {
        userLeaves.push({
          date: startDate,
          color: leave.leaveType?.color as string,
          label: leave.leaveType?.name,
        });
      } else {
        switch (leave.status.state) {
          case LeaveStatusState.WAITING:
          case LeaveStatusState.VALIDN1:
            buildPeriods(
              startDate,
              endDate,
              diff,
              '#e0f7fa',
              leave.leaveType?.name!
            );
            userLeaves.push({
              date: startDate,
              color: '#e0f7fa',
              label: leave.leaveType?.name!,
            });
            break;
          case LeaveStatusState.VALID:
            buildPeriods(
              startDate,
              endDate,
              diff,
              leave.leaveType?.color ? leave.leaveType.color : 'green',
              leave.leaveType?.name!
            );
            userLeaves.push({
              date: startDate,
              color: leave.leaveType?.color ? leave.leaveType.color : 'green',
              label: leave.leaveType?.name!,
            });
            break;
          case LeaveStatusState.REJECTED:
            buildPeriods(
              startDate,
              endDate,
              diff,
              '#F3F3F3',
              leave.leaveType?.name!
            );
            userLeaves.push({
              date: startDate,
              color: '#F3F3F3',
              label: leave.leaveType?.name!,
            });
            break;
        }
      }
    });

    publicHolidays?.forEach((holiday) => {
      if (!holiday.worked) {
        _publicHolidays.push({
          date: new Date(holiday.date),
          color: theme.colors.hifivework[5],
          label: holiday.label,
        });
      }
    });

    userBreakDays?.breakDays?.forEach((breakDay) => {
      _userBreakDays.push({
        date: new Date(breakDay.start),
        color: userBreakDays.color,
        label: 'break-day',
      });
    });

    return [..._userBreakDays, ..._publicHolidays, ...userLeaves];
  }, [leavesList, publicHolidays, userBreakDays]);

  const { data } = useQuery({
    enabled: !!user.company?.id,
    queryKey: ['LeaveTypeService.getLeaveTypes', user.company?.id!, user.id],
    queryFn: () => LeaveTypeService.getLeaveTypes(user.company?.id!, user.id),
  });

  const leaveTypesList: LeaveTypeResponse[] = useMemo(() => {
    if (!data) {
      return [];
    }

    const orderedLeaveTypes = data
      .filter((type) => type.name !== 'Présence')
      .sort((a, b) => {
        return sortLeaveTypes(b, a);
      });

    form.setFieldValue('leaveTypeId', orderedLeaveTypes?.[0]?.id as string);
    return orderedLeaveTypes;
  }, [data]);

  const { mutate: uploadFile, isLoading: isUploadFileLoading } = useMutation({
    mutationFn: (variables: { id: string; file: FileWithPath }) =>
      LeaveService.uploadFile(user.company?.id!, variables.id, variables.file),
    onSuccess: onSuccessCb,
    onError: (error) =>
      showNotification({
        id: 'upload-file-failed',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: createLeave, isLoading: isCreateLeaveLoading } = useMutation({
    mutationFn: (variables: {
      who: string;
      type: string;
      periodStart: string;
      periodEnd: string;
      comment: string;
      startHalf: boolean;
      endHalf: boolean;
      repetitionType: RepetitionType;
      repetitionEndDate: string;
    }) =>
      LeaveService.create(
        user.company?.id!,
        variables.who,
        variables.type,
        variables.periodStart,
        variables.periodEnd,
        variables.comment,
        variables.startHalf,
        variables.endHalf,
        variables.repetitionType,
        variables.repetitionEndDate
      ),
    onSuccess: (leaveData) => {
      const findLeaveType = leaveTypesList.find(
        (type) => type.id === form.values.leaveTypeId
      );
      if (findLeaveType?.requiredAttachment && form.values.files.length !== 0) {
        leaveData.forEach((item) => {
          uploadFile({
            id: item.id,
            file: form.values.files[0],
          });
        });
      } else {
        onSuccessCb();
      }
    },
    onError: (error: any) => {
      showNotification({
        id: error.response.data.errorKey,
        title: t('w.warning'),
        message: handleErrorMessage(error, t),
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
    },
  });

  const loading: boolean = isCreateLeaveLoading || isUploadFileLoading;

  function areStartDateAndEndDateDifferents(): boolean {
    if (!form.values.dateRange[0] || !form.values.dateRange[1]) return false;

    return (
      form.values.dateRange[1] &&
      dayjs(form.values.dateRange[1]).format('DD/MM/YYYY') !==
        dayjs(form.values.dateRange[0]).format('DD/MM/YYYY')
    );
  }

  const startChoices = useMemo(() => {
    if (areStartDateAndEndDateDifferents()) {
      return [
        { value: 'wholeDay', label: t('leave.type.wholeDay') },
        { value: 'afternoon', label: t('leave.type.afternoon') },
      ];
    } else {
      return [
        { value: 'wholeDay', label: t('leave.type.wholeDay') },
        { value: 'morning', label: t('leave.type.morning') },
        { value: 'afternoon', label: t('leave.type.afternoon') },
      ];
    }
  }, [form.values.dateRange[0], form.values.dateRange[1]]);

  const endChoices = useMemo(
    () => [
      { value: 'wholeDay', label: t('leave.type.wholeDay') },
      { value: 'morning', label: t('leave.type.morning') },
    ],
    []
  );

  const repetitionTypes = useMemo(
    () => [
      {
        value: RepetitionType.NO_REPETITION,
        label: t('leave.repetitionType.noRepetition'),
      },
      {
        value: RepetitionType.EVERY_DAY,
        label: t('leave.repetitionType.everyDay'),
      },
      {
        value: RepetitionType.EVERY_WEEK,
        label: t('leave.repetitionType.everyWeek'),
      },
      {
        value: RepetitionType.EVERY_OTHER_WEEK,
        label: t('leave.repetitionType.everyOtherWeek'),
      },
    ],
    []
  );

  function compare(a: string, b: string): number {
    const fa = a?.toLowerCase(),
      fb = b?.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  }

  function sortLeaveTypes(b: LeaveTypeResponse, a: LeaveTypeResponse): number {
    if (b.order !== a.order) {
      // @ts-ignore
      return b.order - a.order;
    } else if (b.order === a.order) {
      return compare(a.name, a.name);
    }

    return -1;
  }

  function getRequiredField(field: string): boolean | undefined {
    const _leaveTypes = [...leaveTypesList];
    if (_leaveTypes.length > 0) {
      const findLeaveType = _leaveTypes.find(
        (type) => type.id === form.values.leaveTypeId
      );
      if (findLeaveType) {
        switch (field) {
          case 'attachment':
            return findLeaveType.requiredAttachment;
          case 'repeatable':
            return findLeaveType.repeatable;
          default:
            return findLeaveType.requiredComment;
        }
      }
    }
  }

  function resetFormValues(): void {
    form.setFieldValue('leaveTypeId', data?.[0]?.id ? data[0].id : '');
    form.setFieldValue('repetitionType', RepetitionType.NO_REPETITION);
    form.setFieldValue('repetitionEndDate', new Date());
    form.setFieldValue('dateRange', [new Date(), new Date()]);
    form.setFieldValue('start', 'wholeDay');
    form.setFieldValue('end', 'wholeDay');
    form.setFieldValue('comment', '');
    form.setFieldValue('files', []);
  }

  function closeModal(): void {
    onClose();
    resetFormValues();
  }

  function onCreateLeaveFormSubmit(values: FormValues): void {
    function getPeriodStart() {
      if (values.start === 'afternoon') {
        return milliToFullDateString(
          dayjs(values.dateRange[0]).hour(12).minute(0).valueOf()
        );
      } else {
        return milliToFullDateString(
          dayjs(values.dateRange[0]).hour(0).minute(0).valueOf()
        );
      }
    }

    function getPeriodEnd() {
      if (areStartDateAndEndDateDifferents()) {
        if (values.end === 'morning') {
          return milliToFullDateString(
            dayjs(values.dateRange[1]).hour(11).minute(59).valueOf()
          );
        } else {
          return milliToFullDateString(
            dayjs(values.dateRange[1]).hour(23).minute(59).valueOf()
          );
        }
      } else {
        switch (values.start) {
          case 'morning':
            return milliToFullDateString(
              dayjs(values.dateRange[0]).hour(11).minute(59).valueOf()
            );
          default:
            return milliToFullDateString(
              dayjs(values.dateRange[0]).hour(23).minute(59).valueOf()
            );
        }
      }
    }

    const data = {
      who: user.id,
      type: values.leaveTypeId,
      periodStart: getPeriodStart(),
      periodEnd: getPeriodEnd(),
      comment: values.comment,
      startHalf: values.start === 'afternoon' || values.start === 'morning',
      endHalf: values.end === 'morning',
      repetitionType: values.repetitionType,
      repetitionEndDate: milliToFullDateString(
        dayjs(values.repetitionEndDate).valueOf()
      ),
    };

    const findLeaveType = leaveTypesList.find(
      (type) => type.id === form.values.leaveTypeId
    );

    if (findLeaveType?.requiredAttachment && form.values.files.length === 0) {
      showNotification({
        id: 'missing-attachment',
        title: t('w.warning'),
        message: t('leave.attachmentRequired'),
        color: 'orange',
        icon: <IconAlertTriangle />,
      });
      return;
    }
    createLeave(data);
  }

  function onSuccessCb() {
    closeModal();
    refresh && refresh();
    queryClient.invalidateQueries({
      queryKey: ['CounterUserService.getByUser', user.id],
    });
    queryClient.invalidateQueries({
      queryKey: ['LeaveService.getLeavesHistory', user.id],
    });
    showNotification({
      id: 'create-leave-success',
      title: t('w.success'),
      message: 'La demande a été envoyée',
      color: 'green',
      icon: <IconCheck />,
    });
  }

  function renderValidators() {
    const n1Name = user.nameOfN1
      ? user.nameOfN1
      : user.division?.n1User?.fullname;
    const n1Id = user.firstManager
      ? user.firstManager
      : user.division?.n1User?.id;
    const n2Name = user.nameOfN2
      ? user.nameOfN2
      : user.division?.n2User?.fullname;
    const n2Id = user.secondManager
      ? user.secondManager
      : user.division?.n2User?.id;

    if (!n1Name && !n1Id && !n2Name && !n2Id)
      return <Text fz={'sm'}>Pas de validateur</Text>;

    return (
      <Flex direction={'column'} gap={'xs'}>
        <Text fz={'sm'} c={'dimmed'}>
          Soumis à validation de :
        </Text>
        {n2Name && n2Id ? (
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
              <AvatarTooltip
                name={n1Name!}
                id={n1Id!}
                division={user.division!}
              />
              <AvatarTooltip
                name={n2Name!}
                id={n2Id!}
                division={user.division!}
              />
            </Avatar.Group>
          </Tooltip.Group>
        ) : (
          <AvatarTooltip name={n1Name!} id={n1Id!} division={user.division!} />
        )}
      </Flex>
    );
  }

  return (
    <form onSubmit={form.onSubmit(onCreateLeaveFormSubmit)}>
      <LoadingOverlay visible={loading} />
      <Grid grow gutter={45}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            data={leaveTypesList.map((type) => {
              return {
                value: type?.id as string,
                label: `${type.name} - ${
                  (type?.maxDays || 0) > 1
                    ? type.maxDays + ' ' + t('leave.type.days') + ' max'
                    : type.maxDays + ' ' + t('leave.type.day') + ' max'
                }`,
              };
            })}
            label={<LabelText text={'Type'} />}
            {...form.getInputProps('leaveTypeId')}
          />
          <Space h={'md'} />
          {getRequiredField('repeatable') && (
            <>
              <Select
                data={repetitionTypes}
                label={<LabelText text={'Type de répétition'} />}
                {...form.getInputProps('repetitionType')}
              />
              <Space h={'md'} />
              {form.values.repetitionType !== RepetitionType.NO_REPETITION && (
                <DateInput
                  label={<LabelText text={'Date de fin de répétition'} />}
                  defaultDate={defaultDate ? defaultDate : new Date()}
                  valueFormat={'DD MMMM YYYY'}
                  minDate={new Date()}
                  {...form.getInputProps('repetitionEndDate')}
                />
              )}
              <Space h={'md'} />
            </>
          )}
          <Group justify={'space-between'}>
            <DateInput
              disabled
              label={<LabelText text={'Date de début'} />}
              value={
                form.values.dateRange[0] ? form.values.dateRange[0] : new Date()
              }
              valueFormat="DD/MM/YYYY"
            />
            <Select
              w={'40%'}
              data={startChoices}
              label={<LabelText text={''} />}
              {...form.getInputProps('start')}
            />
          </Group>
          <Space h={'md'} />
          <Group justify={'space-between'}>
            <DateInput
              disabled
              label={<LabelText text={'Date de fin'} />}
              value={
                form.values.dateRange[1] ? form.values.dateRange[1] : new Date()
              }
              valueFormat="DD/MM/YYYY"
            />
            {areStartDateAndEndDateDifferents() && (
              <Select
                w={'40%'}
                data={endChoices}
                label={<LabelText text={''} />}
                {...form.getInputProps('end')}
              />
            )}
          </Group>
          <Space h={'md'} />
          {getRequiredField('comment') && (
            <TextInput
              required
              placeholder={'Requis'}
              label={<LabelText text={t('w.comment')} />}
              {...form.getInputProps('comment')}
            />
          )}
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <DatePicker
            locale={lang}
            type="range"
            allowSingleDateInRange
            defaultDate={defaultDate ? defaultDate : new Date()}
            numberOfColumns={matches ? 2 : 1}
            weekendDays={[]}
            getDayProps={(date) => {
              const findData = calendarData?.find(
                (leave) =>
                  dayjs(leave.date).format('DD/MM/YYYY') ===
                  dayjs(date).format('DD/MM/YYYY')
              );
              if (date.getDate() === findData?.date.getDate()) {
                return {
                  style: {
                    backgroundColor: findData?.color,
                    color: 'var(--mantine-color-hifivework-2)',
                  },
                };
              }

              return {};
            }}
            {...form.getInputProps('dateRange')}
          />
        </Grid.Col>
      </Grid>
      {getRequiredField('attachment') && (
        <Grid>
          <Grid.Col span={12}>
            <CustomDropzone
              onDrop={(files) => form.setFieldValue('files', files)}
              files={form.values.files}
              mimeType={'several'}
            />
          </Grid.Col>
        </Grid>
      )}
      <Space h={'md'} />
      <Group justify={'space-between'}>
        {renderValidators()}
        <Group>
          <Button variant={'subtle'} onClick={closeModal}>
            {t('w.cancel')}
          </Button>
          <Button type={'submit'}>Envoyer la demande</Button>
        </Group>
      </Group>
    </form>
  );
}
