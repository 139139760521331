type Props = {
  companyCountryCode: string | undefined;
};

export default function useCurrency({ companyCountryCode }: Props) {
  switch (companyCountryCode) {
    case 'CI':
    case 'SN':
    case 'BJ':
    case 'ML':
    case 'BF':
    case 'NE':
    case 'TG':
      return 'XOF';
    case 'CM':
    case 'CG':
    case 'GQ':
    case 'GA':
    case 'CF':
    case 'TD':
      return 'XAF';
    case 'MU':
      return 'MUR';
    case 'CD':
      return 'CDF';
    case 'TN':
      return 'TND';
    case 'DZ':
      return 'DZD';
    case 'GN':
      return 'GNF';
    case 'MA':
      return 'MAD';
    case 'MR':
      return 'MRU';
    case 'BI':
      return 'BIF';
    case 'RW':
      return 'RWF';
    case 'KM':
      return 'KMF';
    case 'DJ':
      return 'DJF';
    case 'MG':
      return 'MGA';
    case 'SC':
      return 'SCR';
    case 'FR':
    default:
      return 'EUR';
  }
}
