import {
  Chip,
  Grid,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Skeleton,
  Space,
  TextInput,
  Title,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import CompanyFeaturesWarningMessage from '../../features/company/features/components/CompanyFeaturesWarningMessage';
import DailyworkConfirmToActive from '../../features/company/features/components/Dailywork/DailyworkConfirmToActive';
import useCompany from '../../hooks/useCompany';
import CkModuleManagerServiceMS from '../../services/CkModuleManagerServiceMS';
import ModuleWithParamsService from '../../services/ModuleWithParamsService';
import { ViewModule } from '../../types/api/payload/module';
import {
  type ModuleDetails,
  ModuleName,
} from '../../types/api/response/module';
import handleErrorMessage from '../../utils/handleErrorMessage';
import ModuleToEditModal from '../ModuleToEditModal';
import CustomModuleCardItem from './CustomModuleCardItem';

type Props = {
  view: ViewModule;
};

function ModulesSkeleton(props: { width: number }) {
  return (
    <Grid gutter={'lg'}>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, md: 6, lg: props.width < 1920 ? 4 : 3 }}>
        <Skeleton height={230} width={'100%'} radius={'md'} />
      </Grid.Col>
    </Grid>
  );
}

export default function CustomModuleCards({ view }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId } = useCompany(user);
  const [modulesList, setModulesList] = useState<ModuleDetails[]>([]);
  const filterableTypes = useMemo(() => {
    const _result = ['ALL'];
    const _modulesTypes: string[] = modulesList
      ?.map((item) => item.description.types)
      .flatMap((item) => item)
      .filter((x, i, a) => a.indexOf(x) === i);
    return _result.concat(_modulesTypes);
  }, [modulesList]);
  const [type, setType] = useState<string>(filterableTypes[0]);
  const [active, setActive] = useState<string>('');
  const [moduleToEdit, setModuleToEdit] = useState<null | ModuleDetails>(null);
  const { width } = useViewportSize();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: updateModule, isLoading: isUpdateModuleLoading } =
    useMutation({
      mutationFn: (variables: {
        moduleDescriptionId: string;
        active: boolean;
      }) =>
        CkModuleManagerServiceMS.update(
          companyId,
          variables.moduleDescriptionId,
          variables.active
        ),
      onSuccess: (_data, variables) => {
        showNotification({
          id: `success-update-module-${variables.moduleDescriptionId}`,
          title: t('w.success'),
          message: getSuccessMessage(variables.moduleDescriptionId),
          color: 'green',
          icon: <IconCheck />,
        });
        refresh();
      },
      onError: (error, variables) => {
        showNotification({
          id: `error-update-module-${variables.moduleDescriptionId}`,
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });
  const { mutate: updateModuleParams, isLoading: updateModuleParamsIsLoading } =
    useMutation({
      mutationFn: (variables: { apiUrl: string; payload: any }) =>
        ModuleWithParamsService.updateModuleParams(
          variables.apiUrl,
          companyId,
          variables.payload
        ),
      onSuccess: () => {
        showNotification({
          id: `success-update-module-params`,
          message: t('w.success'),
          color: 'green',
          icon: <IconCheck />,
        });
        refresh();
        setModuleToEdit(null);
      },
      onError: (err) =>
        showNotification({
          id: `error-update-module-params`,
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        }),
    });
  const { isLoading, refetch } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId, view],
    queryFn: () => CkModuleManagerServiceMS.list(companyId, view),
    onSuccess: (data) => {
      setModulesList(
        data.filter((item) => {
          return !(
            item.name === ModuleName.CharteredAccountant && !item.active
          );
        })
      );
    },
  });
  useEffect(() => {
    if (type === filterableTypes[0]) {
      setActive('');
    }
  }, [type]);

  useEffect(() => {
    if (Boolean(user.onBoardingId)) {
      navigate('/home');
    }
  }, [user]);

  function getSuccessMessage(moduleId: string): ReactNode {
    const findModule = modulesList.find(
      (module) => module.description.id === moduleId
    );
    if (findModule) {
      return t(`module.successfullyUpdated`, t(`module.${findModule.name}`));
    }
  }

  function onActiveChangeModule(event: any, module: ModuleDetails) {
    // no activatable
    if (!module.description.activatable) return;

    if (module.description.directUrl) return;

    // generic activation route, as the module has no parameters
    if (!module.description.params) {
      updateModule({
        moduleDescriptionId: module.description.id,
        active: event.currentTarget.checked,
      });
      return;
    }

    //specific activation route because the module has params
    if (!module.configuration) {
      showNotification({
        id: `error-onActiveChangeModule`,
        title: t('w.error'),
        message: t('module.paramsAreRequired'),
        color: 'red',
        icon: <IconX />,
      });
      return;
    }

    let payload: any;

    if (module.configuration.params) {
      payload = {
        ...module.configuration.params,
        active: event.currentTarget.checked,
      };
    } else {
      payload = {
        active: event.currentTarget.checked,
      };
    }

    if (module.description.paid && event.currentTarget.checked === true) {
      modals.open({
        modalId: `confirm-to-active-dailywork`,
        title: (
          <Title size={'h3'} component="p">
            Confirmation
          </Title>
        ),
        size: '80%',
        children: (
          <DailyworkConfirmToActive
            updateModuleParams={updateModuleParams}
            module={module}
            payload={payload}
          />
        ),
      });
    } else {
      if (
        payload.hasOwnProperty('variablesPayroll') &&
        !payload.variablesPayroll
      ) {
        payload.variablesPayroll = [];
      }

      updateModuleParams({ apiUrl: module.description.url, payload });
    }
  }

  function handleSearchModulesChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const moduleName = event.currentTarget.value;
    if (moduleName !== '') {
      setModulesList(
        modulesList.filter((module) =>
          t(`module.${module.name}`)
            .toLowerCase()
            .includes(moduleName.toLowerCase())
        )
      );
    } else {
      refresh();
    }
  }

  function filterModulesByTypes(item: ModuleDetails) {
    if (type === filterableTypes[0]) {
      return true;
    } else {
      return item.description.types.find((moduleType) => moduleType === type);
    }
  }

  function filterModulesByActive(item: ModuleDetails) {
    if (active === '') {
      return true;
    } else {
      const activeStr = item.active ? 'active' : 'inactive';
      return activeStr === active;
    }
  }

  function refresh() {
    queryClient.invalidateQueries({
      queryKey: ['CkModuleManagerServiceMS.list', companyId],
    });
    refetch();
  }

  return (
    <ScreenWrapper
      title={
        view === 'companyFeature'
          ? t('w.features')
          : view === 'companyMarketplace'
          ? t('w.marketPlace')
          : t('w.accountantHome')
      }
    >
      <LoadingOverlay
        visible={isUpdateModuleLoading || updateModuleParamsIsLoading}
      />
      {view === 'companyFeature' && <CompanyFeaturesWarningMessage />}
      <Group justify={'space-between'}>
        {isLoading ? (
          <Group gap={'xs'}>
            <Skeleton height={30} width={70} radius={'lg'} />
            <Skeleton height={30} width={70} radius={'lg'} />
            <Skeleton height={30} width={70} radius={'lg'} />
            <Skeleton height={30} width={70} radius={'lg'} />
          </Group>
        ) : (
          <Chip.Group multiple={false} value={type} onChange={setType}>
            <Group gap={'xs'}>
              {filterableTypes.map((_type: string, index) => (
                <Chip key={`${_type}-${index}`} value={_type}>
                  {t(`module.type.${_type}`)}
                </Chip>
              ))}
            </Group>
          </Chip.Group>
        )}
        {view === 'companyFeature' &&
          (isLoading ? (
            <Group gap={'xs'}>
              <Skeleton height={30} width={70} radius={'lg'} />
              <Skeleton height={30} width={70} radius={'lg'} />
            </Group>
          ) : (
            <Chip.Group multiple={false} value={active} onChange={setActive}>
              <Group gap={'xs'}>
                <Chip value={'active'}>{t('w.active')}</Chip>
                <Chip value={'inactive'}>{t('w.inactive')}</Chip>
              </Group>
            </Chip.Group>
          ))}
      </Group>
      <Space h={'lg'} />
      <SimpleGrid cols={3}>
        <div>
          <Skeleton visible={isLoading}>
            <TextInput
              placeholder={t('w.search')}
              onChange={(event) => handleSearchModulesChange(event)}
            />
          </Skeleton>
        </div>
      </SimpleGrid>
      <Space h={'xl'} />
      {isLoading ? (
        <ModulesSkeleton width={width} />
      ) : (
        <Grid gutter={'lg'} style={{ overflow: 'visible' }}>
          {modulesList
            .filter((item) => filterModulesByTypes(item))
            .filter((item) => filterModulesByActive(item))
            .map((module) => (
              <Grid.Col
                span={{ xs: 12, md: 6, lg: width < 1920 ? 4 : 3 }}
                key={module.description.id}
              >
                <CustomModuleCardItem
                  module={module}
                  onActiveChangeModule={(event) =>
                    onActiveChangeModule(event, module)
                  }
                  setModuleToEdit={setModuleToEdit}
                  view={view}
                />
              </Grid.Col>
            ))}
        </Grid>
      )}
      <ModuleToEditModal
        opened={moduleToEdit !== null}
        onClose={() => setModuleToEdit(null)}
        moduleToEdit={moduleToEdit}
        updateModuleParams={updateModuleParams}
        companyId={companyId}
      />
    </ScreenWrapper>
  );
}
