import { Badge, Grid, TextInput, useMantineTheme } from '@mantine/core';
import React from 'react';

import ActionDeleteIcon from '../../../../../../components/icons/ActionDeleteIcon';
import { useI18n } from '../../../../../../contexts/I18nProvider';
import type { DossierProps } from '../../../../../../types/api/response/module';

type Props = {
  index: number;
  form: any;
  item: DossierProps;
};

export default function DossierField({ index, form, item }: Props) {
  const { t } = useI18n();
  const theme = useMantineTheme();

  function renderBadge(status: boolean | null) {
    switch (status) {
      case true:
        return theme.colors.green[4];
      case null:
        return theme.colors.gray[4];
      case false:
        return theme.colors.red[4];
    }
  }

  return (
    <Grid align="center" gutter={'md'}>
      <Grid.Col span={2}>
        <ActionDeleteIcon
          label={t('w.delete')}
          onClick={() => form.removeListItem('dossiers', index)}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <TextInput
          placeholder={t('w.noValue')}
          {...form.getInputProps(`dossiers.${index}.dossierId`)}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <Badge color={renderBadge(item.status ? item.status : null)}>
          {item.status
            ? item.employeeCount
              ? item.employeeCount > 0
                ? `${item.employeeCount} ${t('w.employees')}`
                : `${item.employeeCount} ${t('w.employee')}`
              : t('w.toTest')
            : item.status == null
            ? t('w.toTest')
            : t('com.feature.openPaye.fileCodeDoesNotExist')}
        </Badge>
      </Grid.Col>
    </Grid>
  );
}
