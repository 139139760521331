import { Button, Flex, Grid, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ScreenWrapper from '../../../../../core/layouts/components/ScreenWrapper';
import EndOfMonthService from '../../../../../services/EndOfMonthService';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import type {
  DossierProps,
  ModuleDetails,
  SynchroOpenpayeModuleResponse,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';
import { milliToFullDateString } from '../../../../../utils/format';
import DossierField from './components/DossierField';
import LastSynchroOpenpaye from './components/LastSynchroOpenpaye';
import OpenpayeDossierButton from './components/OpenpayeDossierButton';

interface FormValues {
  dossiers: DossierProps[] | undefined;
  isTest: boolean;
}

type Props = {
  module: ModuleDetails | null;
  companyId: string;
};

export default function SynchroOpenpayeFeatureDossierForm({
  module,
  companyId,
}: Props) {
  const { t } = useI18n();
  const [moduleParams, setModuleParams] = useState(
    module?.configuration?.params as SynchroOpenpayeParams
  );
  const form = useForm<FormValues>({
    initialValues: {
      dossiers: moduleParams?.dossiers || [],
      isTest: false,
    },
  });
  const [disableSynchroResult, setDisableSynchroResult] = useState(
    moduleParams?.lastSynchroEmployee === null
  );
  const [disableSynchro, setDisableSynchro] = useState(
    moduleParams?.dossiers?.length === 0
  );

  const { mutate: updateModuleParamsOpenpaye } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (data: SynchroOpenpayeModuleResponse) => {
      setModuleParams(data.params);
      setDisableSynchro(
        data.params?.dossiers ? data.params.dossiers.length === 0 : true
      );
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t('error.updateModule'),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const actionButtons = useMemo(
    () => [
      <Button
        variant={'outline'}
        onClick={() => synchroEmployee()}
        size={'sm'}
        disabled={disableSynchro}
      >
        {t('w.synchronize')}
      </Button>,
      <Button
        variant={'outline'}
        size={'sm'}
        disabled={disableSynchroResult}
        onClick={() =>
          modals.open({
            modalId: `get-last-synchro-openpaye-modal`,
            title: (
              <Title size={'h3'} component="p">{`${t(
                'module.lastSynchroOpenpaye'
              )} : ${
                moduleParams.lastSynchroEmployee
                  ? milliToFullDateString(moduleParams.lastSynchroEmployee)
                  : t('w.unknown')
              }`}</Title>
            ),
            size: '100%',
            children: (
              <LastSynchroOpenpaye
                closeModal={() =>
                  modals.close('get-last-synchro-openpaye-modal')
                }
                companyId={companyId}
                moduleParams={moduleParams}
              />
            ),
          })
        }
      >
        {t('module.lastSyncho')}
      </Button>,
    ],
    [disableSynchroResult, disableSynchro, moduleParams]
  );

  const { mutate: synchroEmployee } = useMutation({
    mutationFn: () => EndOfMonthService.synchroEmployee(companyId),
    onSuccess: () => {
      setDisableSynchroResult(false);
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: t(`com.feature.openPaye.errors.codeDossierInvalid`),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateOpenpayeParamsSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (module) {
      updateModuleParamsOpenpaye({
        apiUrl: module.description.url,
        payload: {
          active: module?.active ? module.active : false,
          dossiers: values.dossiers,
          isTest: values.isTest,
          password: moduleParams?.password,
          username: moduleParams?.username,
        },
      });
    }
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateOpenpayeParamsSubmit({
          dossiers: values.dossiers,
          isTest: false,
        })
      )}
    >
      <ScreenWrapper
        title={'Codes dossiers'}
        actionButtons={actionButtons}
        isSetTitle={false}
      >
        <Text size={'sm'}>{t('com.feature.openPaye.subtitle')}</Text>
        <Grid grow justify="space-between" align="center" mt={'sm'}>
          <Grid.Col span={6}>
            {form?.values?.dossiers &&
              form.values.dossiers.length > 0 &&
              form.values.dossiers.map((item, index) => (
                <>
                  <DossierField
                    key={index}
                    index={index}
                    form={form}
                    item={item}
                  />
                </>
              ))}
          </Grid.Col>
          <Grid.Col span={6}>
            <Flex justify="center" align={'center'}>
              <OpenpayeDossierButton
                onClick={() =>
                  form.insertListItem('dossiers', {
                    dossierId: '',
                  })
                }
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Flex
          justify={'flex-end'}
          gap={'md'}
          direction="row"
          wrap="wrap"
          mt={'md'}
        >
          <ButtonSave />
        </Flex>
      </ScreenWrapper>
    </form>
  );
}
