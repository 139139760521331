import { Badge, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import type { ExpenseReportStatus } from '../../types/types';
import { getBadgeColor, getBadgeLabelColor } from '../../utils/expenseReport';
import CustomTooltip from '../CustomTooltip';

type Props = {
  status: ExpenseReportStatus;
};

export default function ExpenseStatusBadge({ status }: Props) {
  const { t } = useI18n();
  const hasComment = Boolean(status.comment) && status.state === 'REJECTED';

  return (
    <CustomTooltip
      label={
        <div>
          <Text size="xs">
            {t('w.validator')}: {status.comment?.username}
          </Text>
          <Text size="xs">
            {t('w.refusedComment')}:{' '}
            {status.comment?.comment === '' ? (
              <q>{t('w.noCommentsLeft')}</q>
            ) : (
              <q>{status.comment?.comment}</q>
            )}
          </Text>
          <Text size="xs">
            Date:
            {dayjs(status.stateDate).format('L')}
          </Text>
        </div>
      }
      disabled={!hasComment}
      w={200}
    >
      <Badge
        p={'sm'}
        color={getBadgeColor(status.state)}
        styles={{
          label: {
            textTransform: 'none',
            color: getBadgeLabelColor(status.state),
          },
        }}
      >
        {t(`expenseReport.status.state.${status.state}`)}
      </Badge>
    </CustomTooltip>
  );
}
