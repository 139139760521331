import {
  Anchor,
  Button,
  Checkbox,
  Flex,
  Grid,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import LabelText from '../../../components/LabelText/LabelText';
import PhoneField from '../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import CompanyService from '../../../services/CompanyService';
import OauthServiceMS from '../../../services/OauthServiceMS';
import ProfileService from '../../../services/ProfileService';
import type { SubscribeRequest } from '../../../types/api/payload/company';
import { CountryEnum } from '../../../types/api/response/company';
import type { UserResponse } from '../../../types/api/response/user';
import type { OauthUserProfile } from '../../../types/types';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { MS_WHITE_LABEL_ID } from '../../../variables/GlobalVariables';
import InputEmail from '../../login/components/InputEmail';
import ProfileChoiceModal from '../../login/components/ProfileChoiceModal';
import CountriesSelect from './CountriesSelect';

type Props = {
  user: OauthUserProfile | undefined;
  userPhone: string | undefined;
  userAccessToken: string;
  userRefreshToken: string;
  companyPhone: string | undefined;
  onCompanyPhoneChange: (value: string | undefined) => void;
  handleBackStep: () => void;
  handleFinalStep: () => void;
  onModalClose?: () => void;
};

export default function CompanyForm({
  user,
  userPhone,
  userAccessToken,
  userRefreshToken,
  companyPhone,
  onCompanyPhoneChange,
  handleBackStep,
  handleFinalStep,
  onModalClose,
}: Props) {
  const { updateUser } = useAuth();
  const { t } = useI18n();
  const [countryCode, setCountryCode] = useState<CountryEnum>(CountryEnum.FR);
  const [profileToOauthUser, setProfileToOauthUser] = useState<any>(null);
  const [userProfiles, setUserProfiles] = useState<UserResponse[]>([]);
  const form = useForm({
    initialValues: {
      company: {
        name: '',
        mail: user?.username,
        address1: '',
        zipCode: '',
        city: '',
        divisionLabel: '',
      },
      termsAccepted: false,
    },
    validate: {
      company: {
        mail: (val) =>
          val && (/^\S+@\S+$/.test(val) ? null : t('invalid.email')),
      },
    },
  });

  const { refetch: getUserHfwProfiles } = useQuery({
    enabled: false,
    queryKey: ['ProfileService.getUserHfwProfiles', userAccessToken],
    queryFn: () => ProfileService.getUserHfwProfiles(userAccessToken),
    onSuccess: (data) => setUserProfiles(data),
  });

  const { mutate: mutateUser } = useMutation({
    mutationFn: (userId: string) =>
      ProfileService.updateUserTermsAcceptance(userAccessToken, userId),
    onSuccess: (data) => {
      updateUser(data, userAccessToken, userRefreshToken, null);
    },
  });
  const { refetch: getCurrentUserProfile } = useQuery({
    enabled: false,
    queryKey: ['ProfileService.getCurrentUserProfile', userAccessToken],
    queryFn: () => ProfileService.getCurrentUserProfile(userAccessToken),
    onSuccess: (data) => {
      mutateUser(data.id);
      handleFinalStep();
      showNotification({
        id: 'subscription-success',
        title: t('w.success'),
        message: t('w.yourAccountHasBeenSuccessfullyConfigured'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'subscription-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const { data: _profileToOauthUser, refetch: getProfileToOauthUser } =
    useQuery({
      enabled: false,
      queryKey: ['OauthServiceMS.getProfileToOauthUser', userAccessToken],
      queryFn: () => OauthServiceMS.getProfileToOauthUser(userAccessToken),
      onSuccess: (data) => {
        const profileIds = data.profileIds;
        if (profileIds.length === 1) {
          getCurrentUserProfile();
        } else if (profileIds.length > 1) {
          setProfileToOauthUser(_profileToOauthUser);
          getUserHfwProfiles();
        }
      },
    });

  const { mutate: subscribeViaLoginPage, isLoading } = useMutation({
    mutationFn: (data: SubscribeRequest) =>
      CompanyService.subscribeViaLoginPage(userAccessToken, data),
    onSuccess: () => {
      getProfileToOauthUser();
      if (onModalClose) {
        onModalClose();
      }
    },
    onError: (error: any) => {
      let message;
      if (error.response?.data?.error_description) {
        message = error.response.data.error_description;
      } else {
        message = `Erreur ${error.status} : ${
          error.response.text ? error.response.text : error.message
        }`;
      }
      showNotification({
        id: 'subscriptionError',
        title: t('w.error'),
        message: message,
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  function handleCompanyFormSubmit(values: {
    termsAccepted: boolean;
    company: {
      zipCode: string;
      mail: any;
      divisionLabel: string;
      city: string;
      address1: string;
      name: string;
    };
  }) {
    const data: SubscribeRequest = {
      company: {
        name: values.company.name,
        mail: values.company.mail,
        phone: companyPhone,
        address: {
          address1: values.company.address1,
          address2: '',
          zipCode: values.company.zipCode,
          city: values.company.city,
        },
        countryCode,
      },
      user: {
        mail: user?.username!,
        firstname: user?.firstname!,
        lastname: user?.lastname!,
        phone: userPhone,
      },
    };
    subscribeViaLoginPage(data);
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleCompanyFormSubmit(values))}>
      <LoadingOverlay visible={isLoading} />
      <Stack mx={'md'} mt={'md'}>
        <Grid gutter="lg">
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              required
              label={<LabelText text={t('w.companyName')} />}
              placeholder={t('w.companyName')}
              {...form.getInputProps('company.name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <InputEmail
              value={form.values.company.mail}
              onChange={(event) =>
                form.setFieldValue('company.mail', event.currentTarget.value)
              }
              error={form.errors.email}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <PhoneField onChange={onCompanyPhoneChange} value={companyPhone} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              label={<LabelText text={t('w.address')} />}
              placeholder={t('w.address')}
              {...form.getInputProps('company.address1')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              label={<LabelText text={t('w.zipCode')} />}
              placeholder={t('w.zipCode')}
              {...form.getInputProps('company.zipCode')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              label={<LabelText text={t('w.city')} />}
              placeholder={t('w.city')}
              {...form.getInputProps('company.city')}
            />
          </Grid.Col>
          {MS_WHITE_LABEL_ID === 'AFRICAPAIERH' && (
            <Grid.Col span={12}>
              <CountriesSelect
                value={countryCode}
                onChange={(value) => setCountryCode(value as CountryEnum)}
                label={t('w.countries')}
                disabled={false}
              />
            </Grid.Col>
          )}
          <Grid.Col span={12} mt={'md'}>
            <Checkbox
              label={
                <Text>
                  {t('w.conditionsOfUsePart1')}
                  <Anchor
                    onClick={() =>
                      window.open(
                        MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
                          ? 'https://africapaierh.com/conditions-utilisation'
                          : 'https://www.hifivework.com/conditions-utilisation',
                        '_blank'
                      )
                    }
                    target="_blank"
                  >
                    {t('w.conditionsOfUsePart2')}
                  </Anchor>
                  {t('w.conditionsOfUsePart3')}
                  <Anchor
                    onClick={() =>
                      window.open(
                        MS_WHITE_LABEL_ID === 'AFRICAPAIERH'
                          ? 'https://africapaierh.com/politique-de-confidentialite'
                          : 'https://www.hifivework.com/politique-de-confidentialite',
                        '_blank'
                      )
                    }
                    target="_blank"
                  >
                    {t('w.conditionsOfUsePart4')}
                  </Anchor>
                </Text>
              }
              checked={form.values.termsAccepted}
              onChange={(event) =>
                form.setFieldValue('termsAccepted', event.currentTarget.checked)
              }
            />
          </Grid.Col>
        </Grid>
        <Flex justify={'flex-end'} gap={'md'}>
          <Button variant={'subtle'} onClick={handleBackStep}>
            {t('w.back')}
          </Button>
          <Button disabled={!form.values.termsAccepted} type={'submit'}>
            {t('w.create')}
          </Button>
        </Flex>
      </Stack>
      <ProfileChoiceModal
        open={profileToOauthUser?.profileIds?.length > 1}
        onClose={() => setProfileToOauthUser(null)}
        loginResponse={{
          access_token: userAccessToken,
          refresh_token: userRefreshToken,
          users: userProfiles,
        }}
      />
    </form>
  );
}
