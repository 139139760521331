import { Button, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconClockEdit } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppBar } from '../../contexts/AppBarProvider';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ScreenWrapper from '../../core/layouts/components/ScreenWrapper';
import useCompany from '../../hooks/useCompany';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import { DayOfWeek } from '../../types/api/common';
import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkWeeklyTemplateResponse,
  WeeklyPeriod,
} from '../../types/api/response/dailyWork';
import { ModuleName } from '../../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { hasPermission } from '../../utils/authorization';
import { ActivityTemplatesCrud } from './components/ActivityTemplatesCrud';
import PlanningScheduler from './components/PlanningScheduler';

export const weeklyPeriods: WeeklyPeriod[] = [
  { day: DayOfWeek.MONDAY, period: [] },
  { day: DayOfWeek.TUESDAY, period: [] },
  { day: DayOfWeek.WEDNESDAY, period: [] },
  { day: DayOfWeek.THURSDAY, period: [] },
  { day: DayOfWeek.FRIDAY, period: [] },
  { day: DayOfWeek.SATURDAY, period: [] },
  { day: DayOfWeek.SUNDAY, period: [] },
];

export default function PlanningView() {
  const planningId = 'planning-id-to-print';
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const {
    appBarSelectedDivisions,
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
  } = useAppBar();
  const { divisions } = useFetchDivisions({ companyId });
  const queryClient = useQueryClient();
  const [openedTemplates, { open, close }] = useDisclosure(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    DailyWorkDailyTemplateResponse | DailyWorkWeeklyTemplateResponse | undefined
  >(undefined);

  const selectedDivisions: string[] = useMemo(() => {
    if (!divisions || !appBarSelectedDivisions?.length) return [];

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const dailyWorkModule = getModule(ModuleName.DailyWork);

  useEffect(() => {
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [divisions]);

  function buildAppbarDivisions() {
    let _divisions = [...divisions];

    // managers only see divisions where they are n1, n2 or assistant
    if (
      !hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user
      )
    ) {
      _divisions = _divisions.filter(
        (_div) =>
          _div.n1 === user.id ||
          _div.n2 === user.id ||
          _div.assistant === user.id ||
          _div.id === user.division?.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['CkModuleManagerServiceMS.list', companyId],
    });
  }, []);

  const actionButtons = useMemo(() => {
    if (!dailyWorkModule?.active || openedTemplates) return undefined;

    return [
      <Button
        key={'daily-work-button'}
        disabled={!selectedDivisions.length}
        onClick={() => {
          open();
          setSelectedTemplate(undefined);
        }}
        leftSection={<IconClockEdit />}
      >
        {t('w.presenceEntry')}
      </Button>,
    ];
  }, [selectedDivisions, dailyWorkModule, company, openedTemplates]);

  return (
    <ScreenWrapper title={'Planning'} actionButtons={actionButtons} date>
      <Stack mb={'md'} gap="md">
        {selectedDivisions.length > 0 && dailyWorkModule?.active && (
          <ActivityTemplatesCrud
            onClose={close}
            open={openedTemplates}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
        {company && (
          <PlanningScheduler
            planningId={planningId}
            selectedDivisions={selectedDivisions}
            selectedTemplate={selectedTemplate}
            divisions={divisions}
            company={company}
          />
        )}
      </Stack>
    </ScreenWrapper>
  );
}
