import {Button} from "@mantine/core";
import {IconCirclePlus} from "@tabler/icons-react";
import React from "react";

import {useI18n} from "../../../../../../contexts/I18nProvider";

type Props = {
    onClick: () => void;
};

export default function OpenpayeDossierButton({ onClick }: Props) {
    const { t } = useI18n();

    return (
        <Button
            leftSection={<IconCirclePlus />}
            variant={'outline'}
            onClick={onClick}
            size={"xs"}
        >
            {`${t('w.add')} ${t('w.dossier')}`}
        </Button>
    );
}